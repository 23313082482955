import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from "@progress/kendo-react-form";
import {
  ComboBox,
  ComboBoxChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import useSwal from "../../../hooks/useSwal";
import customerService from "../../../services/customer.service";
import { CRMSystemDetail, UpsertCRMSystemDetail, maskedFields } from "../../../types/customer/CRMSystemDetail";
import { Dictionary } from "../../../types/Dictionary";
import { configInfo } from "../../../types/customer/CRMSystemDetail";
import CustomInput from "../../../components/custom/form/CustomInput";
import useMasterData from "../../../hooks/useMasterData";
import { removeAllSpacesAndConvertToLowercase } from "../../../utils/stringUtils";

interface InitialValues {
  name: string;
  systemType: string | undefined;
  configValues: Dictionary<string> | undefined;
}

const CRMSetting: React.FC = () => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();

  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingCRMDetails, setDeletingCRMDetails] = useState<boolean>(false);
  const [upsertingCRMDetails, setUpsertingCRMDetails] = useState<boolean>(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState<boolean>(true);

  const [error, setError] = useState<string>();
  const [configuring, setConfiguring] = useState<boolean>(false);
  const [crmSystemDetails, setCRMSystemDetails] = useState<CRMSystemDetail>();
  const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);
  const [translations, setTranslations] = useState<Dictionary<string> | undefined>(
    localeCtx?.selectedLocale?.current.componentTranslations["CMSSettings"]
  );

  const [systemTypes, setSystemTypes] = useState<String[] | undefined>();
  const [currentSystemType, setCurrentSystemType] = useState<string>();

  const [initialValues, setInitialValues] = useState<InitialValues>();
  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["CMSSettings"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("CMSSettings");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(localeCtx?.selectedLocale?.previous.componentTranslations["CMSSettings"]);
      localeCtx?.setPreviousAppLocale("CMSSettings");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (key: string, defaultValue: string): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    const fetchCRMDetails = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        try {
          setLoading(true);
          const resp = await customerService.getCustomerCRMSystemDetails(customerId);
          setCRMSystemDetails(resp);

          setSystemTypes(masterData?.data?.crmSystemTypes.map((val, key) => val.name));
          setCurrentSystemType(resp?.crmSystemType?.name);

          setInitialValues({ name: resp?.name, systemType: resp?.crmSystemType?.name, configValues: resp?.crmSystemConfig });
        } catch (err) {
          if (err instanceof AxiosError) {
            setSystemTypes(masterData?.data?.crmSystemTypes.map((val, key) => val.name));
            setCurrentSystemType(masterData?.data?.crmSystemTypes.map((val, key) => val.name)[0]);

            setInitialValues({
              name: '',
              systemType: masterData?.data?.crmSystemTypes.map((val, key) => val.name)[0].toString(),
              configValues: undefined
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCRMDetails();
  }, [auth?.tokenPayload?.CustomerId]);



  const toggleConfiguring = () => {
    setIsUpdateDisabled(true);
    setConfiguring((prevState) => !prevState);
    if (crmSystemDetails !== undefined) {
      setInitialValues({ name: crmSystemDetails.name, systemType: crmSystemDetails?.crmSystemType?.name, configValues: crmSystemDetails?.crmSystemConfig });

      const form = formRef.current as Form;
      form.values['configValues'] = { ...crmSystemDetails?.crmSystemConfig };
      form.values['name'] = crmSystemDetails?.name;
      form.values['systemType'] = crmSystemDetails?.crmSystemType?.name;
    } else {
      // When CRM details don't already exisit for the customer and we click 'Configure'. In that case, we load the configFields for the first SystemType.
      const fieldsToMatch: string[] = configInfo[currentSystemType ? currentSystemType : ''];
      const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
        (acc, field) => {
          acc[field] = ''; // Default value is an empty string
          return acc;
        },
        {} as Dictionary<string>
      );
      setInitialValues({
        name: '',
        systemType: masterData?.data?.crmSystemTypes.map((val, key) => val.name)[0].toString(),
        configValues: newConfigValues
      });
    }
  };

  const onUserConfiguringCancelHandler = () => {
    setConfiguring((prevState) => !prevState);
    if (crmSystemDetails?.crmSystemType?.name !== undefined) {
      setCurrentSystemType(crmSystemDetails?.crmSystemType?.name);
    }
    const form = formRef.current as Form;
    form.resetForm(); // If user enters some info and presses cancel, we want to clear the form.
  };

  const onUserDeleteCRMDetails = async () => {
    try {
      setDeletingCRMDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId && crmSystemDetails) {
        await customerService.deleteCustomerCRMSystemDetails(customerId);
        setCRMSystemDetails(undefined);
        setInitialValues({ name: '', systemType: masterData?.data?.crmSystemTypes.map((val, key) => val.name)[0], configValues: undefined });
        swal.fire({
          icon: "success",
          confirmButtonText: `${translationsLoading
            ? "Ok"
            : fetchLabelKeyTranslation("SwtAltOkText", "Ok")
            }`,
          title: `${translationsLoading
            ? "Customer CRM System Details deleted"
            : fetchLabelKeyTranslation("SwtAltDeleteSuccessTitle", "Customer CRM System Details deleted")
            }`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${translationsLoading
            ? "Error"
            : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
          text: `${err.message}`,
        });
      }
    } finally {
      setDeletingCRMDetails(false);
    }
  }

  const crmConfigureSubmitHandler = async (dataItem: { [name: string]: any; }) => {
    try {
      setUpsertingCRMDetails(true);
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId) {
        const fieldsToMatch: string[] = configInfo[currentSystemType ? currentSystemType : ''];

        const filteredConfigValues: Dictionary<string> = Object.fromEntries(
          Object.entries(dataItem?.configValues)
            .filter(([key]) => fieldsToMatch.includes(key))
        ) as Dictionary<string>;

        let updatedCRMDetails: UpsertCRMSystemDetail = {
          id: crmSystemDetails ? crmSystemDetails.id : 0,
          name: dataItem?.name,
          crmSystemType: currentSystemType ? currentSystemType : "",
          crmSystemConfig: filteredConfigValues
        }

        let updatedCRM: CRMSystemDetail = await customerService.upsertCustomerCRMSystemDetails(customerId, updatedCRMDetails);
        setCRMSystemDetails(updatedCRM);
        setInitialValues({ name: updatedCRM?.name, systemType: updatedCRM?.crmSystemType?.name, configValues: updatedCRM?.crmSystemConfig });
        setConfiguring(false);
        // setManagedBySystem(false);
        swal.fire({
          icon: "success",
          confirmButtonText: `${translationsLoading
            ? "Ok"
            : fetchLabelKeyTranslation("SwtAltOkText", "Ok")
            }`,
          title: `${translationsLoading
            ? "CRM System Details configured"
            : fetchLabelKeyTranslation("SwtAltConfigureSuccessTitle", "CRM System Details configured")
            }`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${translationsLoading
            ? "Error"
            : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
          text: `${err.message}`,
        });
      }
    } finally {
      setUpsertingCRMDetails(false);
    }
  }

  const onSystemTypeChangeHandler = (e: ComboBoxChangeEvent) => {
    setCurrentSystemType(e.value);
    if (e.value) {
      const fieldsToMatch: string[] = configInfo[e.value];
      const newConfigValues: Dictionary<string> = fieldsToMatch.reduce(
        (acc, field) => {
          acc[field] = ''; // Default value is an empty string
          return acc;
        },
        {} as Dictionary<string>
      );

      setInitialValues((prevState) => {
        if (prevState !== undefined) {
          return {
            ...prevState,
            configValues: { ...newConfigValues }
          }
        }
      })
      const form = formRef.current as Form;
      form.values['configValues'] = { ...newConfigValues }
    }
  }

  const inputFieldValidator = (value: string) => {
    if (!value || value.trim().length === 0) {
      return `${translationsLoading
        ? "This field is mandatory."
        : fetchLabelKeyTranslation("ValidationMssg", "This field is mandatory.")
        }`;
    }
    return "";
  };

  const compareState = () => {
    const form = formRef.current as Form;
    const fieldsToMatch: string[] = configInfo[currentSystemType ? currentSystemType : ''];

    const isConfigsDifferent = (): boolean => {
      const hasIncorrectValue: boolean = fieldsToMatch.some((key) => {
        const val = form?.values['configValues'][key];
        if (initialValues && initialValues['configValues']) {
          if (initialValues['configValues'][key] !== val) {
            return true;
          }
        }
      });
      return hasIncorrectValue;
    }

    const isConfigEmpty = (): boolean => {
      const hasEmptyValue: boolean = fieldsToMatch.some((key) => {
        const val = form?.values['configValues'][key];
        if (val == "") {
          return true;
        }
      })
      return hasEmptyValue;
    }

    if (
      (isConfigsDifferent() && (isConfigEmpty() == false)) ||
      ((initialValues?.name !== form?.values?.name) == true) ||
      ((initialValues?.systemType !== currentSystemType) == true)
    ) {
      setIsUpdateDisabled(false);
    } else {
      setIsUpdateDisabled(true);
    }
  };

  // const configField = (fieldName: string, formRenderProps: FormRenderProps) => {
  //   const form = formRef.current as Form;
  //   console.log(initialValues)
  //   if (configValues !== undefined) {
  //     const currVal = configValues[fieldName];
  //     //formRenderProps.valueGetter("name")
  //     switch (fieldName) {
  //       default: return (
  //         <Field
  //           // className="textInput"
  //           // id={fieldName}
  //           name={fieldName}
  //           // required={true}
  //           placeholder={fieldName + "..."}
  //           // value={formRenderProps.valueGetter("configValues")[fieldName].toString()}
  //           value={formRenderProps.valueGetter("name")}
  //           component={CustomInput}
  //         // validator={inputFieldValidator}
  //         // onChange={(e) => updateConfigValues(fieldName, e.value)}
  //         />
  //       )
  //     }
  //   }
  // }

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container viewBoxForm">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {translationsLoading
                    ? "CRM Details"
                    : fetchLabelKeyTranslation("DetailsText", "CRM Details")}
                </span>
                <span className="float-right">
                  {crmSystemDetails && !configuring && (
                    <Button
                      className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                      style={{ height: "26px" }}
                      onClick={toggleConfiguring}
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Edit"
                          : fetchLabelKeyTranslation("EditBtnText", "Edit")}
                      </span>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  )}
                  {crmSystemDetails && !configuring && (
                    <Button
                      className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                      style={{ height: "26px" }}
                      onClick={onUserDeleteCRMDetails}
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Delete"
                          : fetchLabelKeyTranslation("DeleteBtnText", "Delete")}
                      </span>
                      {deletingCRMDetails ? (
                        <Loader
                          type={"infinite-spinner"}
                          size={"small"}
                          themeColor={"primary"}
                        />
                      ) : (
                        <i className="bi bi-trash3"></i>
                      )}
                    </Button>
                  )}
                </span>
              </div>
              {loading && (
                <div className="m-t-30 text-center">
                  <Loader type={"infinite-spinner"} />
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  {!crmSystemDetails && !error && !configuring && !loading && (
                    <div className="float-left w-100 p-t-20 p-b-20">
                      <div className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 p-15 p-l-20 d-flex align-items-center">
                        <div className="itmeBoxBtn float-left w-100">
                          <div className="storageListPlaceholder p-20">
                            <div className="text-center p-b-5">
                              <span className="tx-red">
                                {translationsLoading
                                  ? "You have not set up CRM Service. Please configure your CRM Service details."
                                  : fetchLabelKeyTranslation(
                                    "InfoText",
                                    "You have not set up CRM Service. Please configure your CRM Service details."
                                  )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                              <Button
                                onClick={toggleConfiguring}
                                className="btn bg-primary text-white"
                                style={{ height: "40px", width: "150px" }}
                              >
                                {translationsLoading
                                  ? "Configure"
                                  : fetchLabelKeyTranslation("ConfigBtnText", "Configure")}
                                <i className="bi bi-gear-wide-connected fs-20 m-l-8"></i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {!loading && !error && (crmSystemDetails || configuring) && (
                <Form
                  onSubmit={crmConfigureSubmitHandler}
                  ref={formRef}
                  initialValues={initialValues}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Name"
                                : fetchLabelKeyTranslation("NameText", "Name")}
                            </div>
                            <div className="trkCol-dot">:</div>
                            {configuring ?
                              <Field
                                className="textInput"
                                id={"name"}
                                name={"name"}
                                required={true}
                                placeholder={"Name..."}
                                value={formRenderProps.valueGetter("name")}
                                component={CustomInput}
                                validator={inputFieldValidator}
                                onChange={compareState}
                              />
                              :
                              <>
                                <span>{crmSystemDetails?.name}</span>
                              </>
                            }
                          </div>
                        </div>

                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {translationsLoading
                                ? "System Type"
                                : fetchLabelKeyTranslation("SystemTypeText", "System Type")}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">:</div>
                            {/* <div className="trkCol-p"> */}
                            {configuring ?
                              <ComboBox
                                className="comboBoxInput"
                                name="systemType"
                                data={systemTypes}
                                defaultValue={initialValues?.systemType}
                                onChange={onSystemTypeChangeHandler}
                              />
                              :
                              crmSystemDetails?.crmSystemType?.name}
                            {/* </div> */}
                          </div>
                        </div>

                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi text-decoration-underline">
                              {translationsLoading
                                ? "Configs"
                                : fetchLabelKeyTranslation("ConfigsText", "Configs")}
                            </div>
                            {/* <div className="trkCol-dot">:</div> */}
                          </div>
                        </div>
                        {
                          Object.entries(configInfo).map((val: [string, string[]], key: number) => {
                            return (
                              <div key={key}>
                                {(val[0].toString() === (configuring ? currentSystemType : initialValues?.systemType)) && (
                                  <>
                                    {val[1].map((value: string, index: number) => {
                                      return (
                                        <div className="trk-row border-bottom-solid border-w-1 border-black-1" key={index}>
                                          <div className="trkCol">
                                            <div className="trkCol-h font-weight-semi">
                                              {translationsLoading
                                                ? `${value}`
                                                : fetchLabelKeyTranslation(`Configs_${removeAllSpacesAndConvertToLowercase(val[0] + '_' + value)}_Text`, `${value}`)}
                                            </div>
                                            {/* The first parameter of the above fetchLabelKeyTranslation will give us text like - Configs_agile_apiurl_Text */}
                                            <div className="trkCol-dot">:</div>
                                            <div className="trkCol-p" key={index}>
                                              {(configuring && initialValues && initialValues['configValues']) ? (
                                                <Field
                                                  className="textInput"
                                                  id={value.toString()}
                                                  name={`configValues['${value}']`} // This represents the configValues inside initialValues
                                                  required={true}
                                                  type={maskedFields.has(removeAllSpacesAndConvertToLowercase(value)) ? "password" : undefined}
                                                  placeholder={value + "..."}
                                                  value={initialValues['configValues'][value]}
                                                  component={CustomInput}
                                                  validator={inputFieldValidator}
                                                  onChange={compareState}
                                                />
                                                // value={formRenderProps.valueGetter("configValues") ? formRenderProps.valueGetter("configValues")[value] : ''}
                                                // configField(value, formRenderProps)
                                              ) : (
                                                <>
                                                  {
                                                    maskedFields.has(removeAllSpacesAndConvertToLowercase(value))
                                                      ?
                                                      <span>{`\u25CF`.repeat(20)}</span>
                                                      :
                                                      <span>{(crmSystemDetails !== undefined && crmSystemDetails.crmSystemConfig !== undefined) ? crmSystemDetails.crmSystemConfig[value] : ''}</span>

                                                  }
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </>
                                )}
                              </div>
                            )
                          })
                        }
                        {configuring &&
                          <div className="row p-t-8">
                            <div className="col-md-12 text-right">
                              <Button
                                onClick={onUserConfiguringCancelHandler}
                                className="btn bg-black-5 m-r-5">
                                {translationsLoading
                                  ? "Cancel"
                                  : fetchLabelKeyTranslation("CancelBtnText", "Cancel")}
                              </Button>

                              <Button
                                type={"submit"}

                                className={`btn bg-primary text-white ${isUpdateDisabled
                                  ? "disabledBtn"
                                  : ""
                                  }`}
                                disabled={isUpdateDisabled}
                              >
                                {upsertingCRMDetails ? (
                                  <Loader
                                    size={"small"}
                                    type={"infinite-spinner"}
                                    themeColor={"light"}
                                  />
                                ) : (
                                  <>
                                    {translationsLoading
                                      ? "Update"
                                      : fetchLabelKeyTranslation("UpdateBtnText", "Update")}
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        }
                      </div>
                    </FormElement>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CRMSetting;