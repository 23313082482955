import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../../components/custom/form/CustomInput";
import { EmailValidator } from "../../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import useAuth from "../../hooks/useAuth";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { SendEmailRequest } from "../../types/SendEmailRequest";
import { AxiosError } from "axios";
import useSwal from "../../hooks/useSwal";
import emailService from "../../services/email.service";

interface MsTeamsEmailSharingPopupProps {
  policyScript: string;
  emailTemplate: string;
  onCloseEmail: () => void;
}

const MsTeamsEmailSharingPopup: React.FC<MsTeamsEmailSharingPopupProps> = ({
  policyScript, emailTemplate, onCloseEmail
}) => {
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const externalUserFormRef = useRef<any>();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "MsTeamsSharingPopup"
    ]
  );

  const [usersError, setUsersError] = useState<string | undefined>();
  const [loader, setLoader] = useState<boolean>(false);
  const [users, setUsers] = useState<string[]>([]);
  const [shareToMeScriptChecked, setShareToMeScriptChecked] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "MsTeamsSharingPopup"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "MsTeamsSharingPopup"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "MsTeamsSharingPopup"
        ]
      );
      localeCtx?.setPreviousAppLocale("MsTeamsSharingPopup");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const addExternalUser = (dataItem: { [name: string]: any }) => {
    setUsersError(undefined);
    const enteredVal = dataItem.email as string;
    if (!EmailValidator(enteredVal)) {
      setUsersError(
        translationsLoading
          ? "Please provide a valid email!"
          : fetchLabelKeyTranslation(
              "ErrorValidEmail",
              "Please provide a valid email!"
            )
      );
      return;
    }
    if (enteredVal === auth?.user?.email) {
      setShareToMeScriptChecked(true);
    }

    let exists = false;
    users.forEach((e) => {
      if (e.trim() === enteredVal.trim()) {
        setUsersError(
          translationsLoading
            ? "Email already added!"
            : fetchLabelKeyTranslation(
                "ErrorEmailAlreadyAdded",
                "Email already added!"
              )
        );
        exists = true;
      }
    });

    if (exists) {
      return;
    }
    setUsers((prevState) => [...prevState, enteredVal]);
    const form = externalUserFormRef.current as Form;
    form.resetForm();
  };

  const deleteExternalUser = (u: string) => {
    if (auth?.user?.email === u) {
      setShareToMeScriptChecked(false);
    }
    const updatedExternalUsers = users.filter((e) => e !== u);
    setUsers(updatedExternalUsers);
    const form = externalUserFormRef.current as Form;
    form.resetForm();
  };

  const updateShareToMeScript = (event: CheckboxChangeEvent) => {
    if (event.value) {
      setUsers((prevState) => [...prevState, auth?.user?.email ?? ""]);
    } else {
      deleteExternalUser(auth?.user?.email ?? "");
    }
    setShareToMeScriptChecked(event.value);
  };

  const handleSubmitEmailSharing = async() => {
    const hostname = window.location.hostname;
    const appUrl =
      hostname === "localhost"
        ? `http://${hostname}:3000`
        : `https://${hostname}`;
    var emailVariable: Dictionary<string> = {
      "@@RecordingPolicyScript@@": policyScript 
    };
    var sendEmailRequest: SendEmailRequest = {
      appUrl: appUrl,
      toEmailAddresses: users,
      emailTemplateType: emailTemplate,
      emailVariables: emailVariable
    }
    console.log(sendEmailRequest);

    try {
      setLoader(true);
      const response = await emailService.sendEmail(sendEmailRequest);
      const swalOptions : SweetAlertOptions<any, any> = {
        icon: "success",
        title: "Scripts Shared",
        text: "Your email has been sent",
      }
      await swal.fire(swalOptions);
      onCloseEmail();
    } catch (err) {
      if (err instanceof AxiosError) {
        const swalOptions : SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Could not send scripts.",
        }
        await swal.fire(swalOptions);
      }
    } finally {
      setLoader(false);
    }
    
  };

  return (
    <div>
      <Dialog
        className="dialogShareList"
        title={`${
          translationsLoading
            ? "Send Script"
            : fetchLabelKeyTranslation("HeadingSendScript", "Send Script")
        }`}
        onClose={onCloseEmail}
        style={{ zIndex: 99 }}
      >
        <div
          className="shareList tab-line tabContentWidth-100 tabContentP-0"
          style={{ minWidth: "250px", maxWidth: "360px" }}
        >
          <div>
            <div className="detailColBox p-r-10">
              <div className="UserLiSearch p-b-5">
                <Form
                  ref={externalUserFormRef}
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={addExternalUser}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <fieldset
                        className={"k-form-fieldset"}
                        style={{ padding: "10px 0 0 0" }}
                      >
                        <span>
                          {translationsLoading
                            ? "Send Script"
                            : fetchLabelKeyTranslation(
                                "HeadingSendScript",
                                "Send Script"
                              )}
                        </span>
                        <div className="d-flex align-items-center justify-content-between">
                          <Field
                            name={"email"}
                            type={"email"}
                            placeholder={
                              translationsLoading
                                ? "To: email"
                                : fetchLabelKeyTranslation(
                                    "PlaceholderFieldOne",
                                    "To: email"
                                  )
                            }
                            value={formRenderProps.valueGetter("email")}
                            component={CustomInput}
                            style={{ height: "32px", width: "315px" }}
                          />
                          <button
                            type="submit"
                            className="btn btn-link cursor-pointer"
                            disabled={
                              !formRenderProps.allowSubmit &&
                              formRenderProps.valid
                            }
                          >
                            <span
                              className="fs-20 text-primary radius-50 d-flex align-items-center justify-content-center"
                              style={{ height: "30px", width: "20px" }}
                              title={
                                translationsLoading
                                  ? "Add"
                                  : fetchLabelKeyTranslation("BtnAdd", "Add")
                              }
                            >
                              <i className="bi bi-send-plus-fill"></i>
                            </span>
                          </button>
                        </div>
                      </fieldset>
                      <Error>{usersError}</Error>
                    </FormElement>
                  )}
                />
              </div>
              <div
                style={{
                  maxHeight: "300px",
                  minHeight: "266px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    {users.map((u, idx) => {
                      return (
                        <tr key={u}>
                          <td>
                            <CustomUserGridInfo
                              userInfo={{
                                id: idx,
                                loginUserId: 0,
                                email: u,
                                firstName: "",
                                lastName: "",
                                isArchived: false,
                              }}
                              iconCls="bi bi-envelope"
                            />
                          </td>
                          <td>
                            <div className="selectUserLi p-r-15">
                              <a
                                className="cursor-pointer"
                                onClick={() => deleteExternalUser(u)}
                              >
                                <span
                                  className="fs-16 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                  style={{ height: "30px", width: "30px" }}
                                  title={
                                    translationsLoading
                                      ? "Delete"
                                      : fetchLabelKeyTranslation(
                                          "BtnDelete",
                                          "Delete"
                                        )
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </span>
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="selectDurationLabel float-left w-100 p-t-10">
            <div className="formBoxRow p-t-5 p-b-5 d-flex align-items-center">
              <div className="formBoxLabel p-r-5 fs-14">
                {translationsLoading
                  ? "Share to me:"
                  : fetchLabelKeyTranslation(
                      "FieldTitleShareToMe",
                      "Share to me:"
                    )}
              </div>
              <div className="formBoxAction">
                <div className="formInput">
                  <Checkbox
                    checked={shareToMeScriptChecked}
                    className="p-r-5"
                    onChange={updateShareToMeScript}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={onCloseEmail}
          >
            {translationsLoading
              ? "Cancel"
              : fetchLabelKeyTranslation("BtnCancel", "Cancel")}
          </button>
          <Button
            themeColor={"primary"}
            fillMode={"solid"}
            disabled={users.length === 0 || loader}
            onClick={handleSubmitEmailSharing}
          >
            {loader ? (
              <Loader size="small" type="infinite-spinner" themeColor="light" />
            ) : translationsLoading ? (
              "Share"
            ) : (
              fetchLabelKeyTranslation("BtnShare", "Share")
            )}
          </Button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default MsTeamsEmailSharingPopup;
