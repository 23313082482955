import ReactDOM from "react-dom/client";
import * as microsoftTeams from "@microsoft/teams-js";
import App from "./App";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { BrandProvider } from "./contexts/BrandProvider";
import { LocaleProvider } from "./contexts/LocaleProvider";
import { AuthProvider } from "./contexts/AuthProvider";
import { MasterDataProvider } from "./contexts/MasterDataProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import MSTeamsActiveCalls from "./pages/msteams/MSTeamsActiveCalls";
import NotFoundError from "./pages/error/NotFoundError";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const hostname = window.location.hostname;
export const appUrl =
  hostname === "localhost" ? `http://${hostname}:3000` : `https://${hostname}`;
  
const clientInstance = new PublicClientApplication({
  auth: {
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    redirectUri: `${appUrl}/`,
    postLogoutRedirectUri: appUrl,
  },
});

export const checkInTeams = (): boolean => {
  // eslint-disable-next-line dot-notation
  const microsoftTeamsLib = microsoftTeams || (window as any)["microsoftTeams"];

  if (!microsoftTeamsLib) {
    return false; // the Microsoft Teams library is for some reason not loaded
  }

  if ((window.parent === window.self && (window as any).nativeInterface) ||
    window.name === "embedded-page-container" ||
    window.name === "extension-tab-frame") {
    return true;
  }
  return false;
};

const isInTeams = checkInTeams();

root.render(
  <Router>
    {isInTeams ? (
      <Routes>
        <Route path="/msteams-active-calls" element={<MSTeamsActiveCalls />} />
        <Route path="*" element={<NotFoundError />} />
      </Routes>
    ) 
    : (
      <BrandProvider>
        <LocaleProvider>
          <AuthProvider>
            <MsalProvider instance={clientInstance}>
              <MasterDataProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </MasterDataProvider>
            </MsalProvider>
          </AuthProvider>
        </LocaleProvider>
      </BrandProvider>
    )
    }
  </Router>
);
