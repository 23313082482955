import axios from "axios";
import { REACT_MSTEAMS_APIGATEWAY_BASEURL } from "../constants";


export const axiosWithAuthForMsTeams = axios.create({
    baseURL: REACT_MSTEAMS_APIGATEWAY_BASEURL,
    headers: {
        "Content-Type": "application/json",
    },
});


