import * as msTeams from '@microsoft/teams-js';
import { MsTeamsCallRecording } from "../types/ms-teams/MsTeamsCallRecording";
import { MsTeamsCallRecordingDetail } from "../types/ms-teams/MsTeamsCallRecordingDetail";
import { axiosWithAuthForMsTeams } from "../utils/msTeamsCustomAxios";

const msTeamsCallRecordingService = {

    getMsTeamsAccessToken: async (successCallbackFunction: Function)=> {
        msTeams.initialize();
        var authTokenRequest = {
            successCallback: successCallbackFunction,
            failureCallback: (error: any) => { console.log("Fatching MsTeams Token Failed: " + error); },
            resources: []
        };
        msTeams.authentication.getAuthToken(authTokenRequest);
    },
    
    getCallsByUser: async (token: string): Promise<MsTeamsCallRecording[]> => {
        try {
            var response = await axiosWithAuthForMsTeams.get(`/v1.0/User/Calls`, {
                headers: { Authorization: 'Bearer ' + token }
            });
            const callRecordings: MsTeamsCallRecording[] = response.data;
            return callRecordings;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    getCallRecordingDetailbyCallId: async (token: string, callId: string): Promise<MsTeamsCallRecordingDetail> => {
        try {
            var response = await axiosWithAuthForMsTeams.get(`/v1.0/MsTeamsCallRecording/${callId}`, {
                headers: { Authorization: 'Bearer ' + token }
            });
            const callRecordingDetail: MsTeamsCallRecordingDetail = response.data;
            return callRecordingDetail;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    pauseCallRecordingbyCallId: async (token: string, callId: string): Promise<MsTeamsCallRecordingDetail> => {
        try {
            var response = await axiosWithAuthForMsTeams.put(`/v1.0/MsTeamsCallRecording/${callId}/Pause`, {}, {
                headers: { Authorization: 'Bearer ' + token }
            });
            const callRecordingDetail: MsTeamsCallRecordingDetail = response.data;
            return callRecordingDetail;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    resumeCallRecordingbyCallId: async (token: string, callId: string): Promise<MsTeamsCallRecordingDetail> => {
        try {
            var response = await axiosWithAuthForMsTeams.put(`/v1.0/MsTeamsCallRecording/${callId}/Resume`, {}, {
                headers: { Authorization: 'Bearer ' + token }
            });
            const callRecordingDetail: MsTeamsCallRecordingDetail = response.data;
            return callRecordingDetail;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

    discardCallRecordingbyCallId: async (token: string, callId: string): Promise<MsTeamsCallRecordingDetail> => {
        try {
            var response = await axiosWithAuthForMsTeams.delete(`/v1.0/MsTeamsCallRecording/${callId}`, {
                headers: { Authorization: 'Bearer ' + token }
            });
            const callRecordingDetail: MsTeamsCallRecordingDetail = response.data;
            return callRecordingDetail;
        } catch (err) {
            console.log(err);
            throw err;
        }
    },

}

export default msTeamsCallRecordingService;
