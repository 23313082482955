import { createContext, useEffect, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { TinyUser } from "../types/user";
import jwt_decode from "jwt-decode";
import { TokenPayload } from "../types/context";
import { AccessPermissionEnum } from "../enums/accessPermissionEnum";
import { parseJSON } from "../utils/customJsonParser";
import usersService from "../services/users.service";
import { useNavigate } from "react-router-dom";

type AuthContextType = {
  user?: TinyUser;
  accessToken?: string;
  tokenPayload?: TokenPayload;
  userPermissions: number[];
  setUserAuthToken: (token?: string) => void;
  checkUserAccess: (permission: AccessPermissionEnum) => boolean;
  checkTopicAvailibility: () => boolean;
  setUser: (user: TinyUser) => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

const decodeToken = (accessToken?: string): TokenPayload | undefined => {
  if (accessToken) {
    try {
      const decodedToken = jwt_decode<TokenPayload>(accessToken);
      return decodedToken;
    } catch (err) {
      return undefined;
    }
  }
};

const getUserPermissionsFromToken = (accessToken?: string): number[] => {
  if (accessToken) {
    try {
      const decodedToken = jwt_decode<TokenPayload>(accessToken);
      const permissions = parseJSON<number[]>(decodedToken.AccessPermissions);
      return permissions ?? [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }
  return [];
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  let navigate = useNavigate();
  const [user, setUser] = useState<TinyUser>();
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(
    "VoiceAIToken",
    undefined
  );
  const [tokenPayload, setTokenPayload] = useState<TokenPayload | undefined>(
    decodeToken(accessToken)
  );
  const [userPermissions, setUserPermissions] = useState<number[]>(
    getUserPermissionsFromToken(accessToken)
  );

  useEffect(() => {
    if (accessToken && !user) {
      getAuthUser();
    }
  }, [accessToken]);

  const getAuthUser = async () => {
    try {
      const response = await usersService.getCurrentTinyUser();
      setUser(response);
    } catch (err) {
      console.error(err);
      navigate("/loginIncomplete");
    }
  };

  const checkUserAccess = (permission: AccessPermissionEnum) => {
    return userPermissions.includes(permission);
  };

  const setUserAuthToken = (token?: string) => {
    if (token) {
      try {
        const decodedToken = jwt_decode<TokenPayload>(token);
        setTokenPayload(decodedToken);
        setAccessToken(token);
        getAuthUser();
        const permissions = parseJSON<number[]>(decodedToken.AccessPermissions);
        if (permissions) {
          setUserPermissions(permissions);
        }
      } catch (err) {
        setTokenPayload(undefined);
        setAccessToken(undefined);
        setUserPermissions([]);
        setUser(undefined);
        console.error(err);
      }
    } else {
      setTokenPayload(undefined);
      setAccessToken(undefined);
      setUserPermissions([]);
      setUser(undefined);
    }
  };

  const checkTopicAvailibility = () => {
    var isTopicAvailable = false;
    if (tokenPayload) {
      isTopicAvailable =
        tokenPayload.LocaleCode == "en-GB" ||
        tokenPayload.LocaleCode == "en-US";
    } else {
      isTopicAvailable = false;
    }
    return isTopicAvailable;
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        accessToken,
        tokenPayload,
        userPermissions,
        setUserAuthToken,
        checkUserAccess,
        checkTopicAvailibility,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
