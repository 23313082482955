import React, { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridHeaderCellProps, GridNoRecords } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { Tooltip } from '@progress/kendo-react-tooltip';
import msTeamsCallRecordingService from '../../services/msteamsCallRecording.service';
import { MsTeamsCallRecordingDetail } from '../../types/ms-teams/MsTeamsCallRecordingDetail';
import CustomSearchFieldTextInput from '../../components/custom/form/CustomSearchFieldTextInput';
import { formatDateTime } from '../../utils/dateTimeUtils';

const tempData: MsTeamsCallRecordingDetail[] = [
  {
    callId: "hfencgwsn",
    user: {userId: "ahfonfhdmn", userName: "Mahesh Yogi"},
    participants: [{userId: "newbrejjf", userName: "Pulkit Agrawal"}],
    startDateTime: "2023-01-24 11:20:06.0000000",
    isPaused: false,
    isDiscarded: false
  },
  {
    callId: "uifvjpodnh",
    user: {userId: "ahfonfhdmn", userName: "Mahesh Yogi"},
    participants: [{userId: "rflkehrlok", userName: "Kartik Gupta"}, {userId: "newbrejjf", userName: "Pulkit Agrawal"}],
    startDateTime: "2023-01-24 11:50:44.0000000",
    isPaused: true,
    isDiscarded: false
  },
  {
    callId: "nvnchjcunv",
    user: {userId: "ahfonfhdmn", userName: "Mahesh Yogi"},
    participants: [{userId: "hugmjbui", userName: "Kartik Rawat"}],
    startDateTime: "2023-01-24 13:25:41.0000000",
    isPaused: false,
    isDiscarded: true
  },
]

interface CustomGridCellProps extends GridCellProps {
  dataItem: MsTeamsCallRecordingDetail;
}

interface ProductNameHeaderProps extends GridHeaderCellProps {
  children: any;
}

const MSTeamsActiveCalls: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [callDetails, setCallDetails] = useState<MsTeamsCallRecordingDetail[]>([]);

  useEffect(() => {
   getCallsByUserGUID();
  }, []);

  const getCallsByUserGUID = async () => {
    try{
      setCallDetails([]);
      setLoading(true);
      msTeamsCallRecordingService.getMsTeamsAccessToken(async (token: string) => {
        var callRecordings = await msTeamsCallRecordingService.getCallsByUser(token);
        var _tempCalls: MsTeamsCallRecordingDetail[] = [];
        if(callRecordings && callRecordings.length > 0){
          for(let i = 0; i < callRecordings.length; i++){
            var callDetail = await msTeamsCallRecordingService.getCallRecordingDetailbyCallId(token, callRecordings[i].callId);
            if(callDetail){
              _tempCalls.push(callDetail);
            }
          }
        }
        setCallDetails(_tempCalls);
        setLoading(false);
      });
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const PauseCallRecording = (callId: string) => {
    setLoading(true);
    msTeamsCallRecordingService.getMsTeamsAccessToken(async (token: string) => {
      var callDetail = await msTeamsCallRecordingService.pauseCallRecordingbyCallId(token, callId);
      if(callDetail){
        var _tempCalls = callDetails.map(call => {
          if(call.callId === callId){
            return callDetail;
          }else{
            return call;
          }
        })
        setCallDetails(_tempCalls);
      }
      setLoading(false);
    });
  }

  const ResumeCallRecording = (callId: string) => {
    setLoading(true);
    msTeamsCallRecordingService.getMsTeamsAccessToken(async (token: string) => {
      var callDetail = await msTeamsCallRecordingService.resumeCallRecordingbyCallId(token, callId);
      if(callDetail){
        var _tempCalls = callDetails.map(call => {
          if(call.callId === callId){
            return callDetail;
          }else{
            return call;
          }
        })
        setCallDetails(_tempCalls);
      }
      setLoading(false);
    });
  }

  const DiscardCallRecording = (callId: string) => {
    setLoading(true);
    msTeamsCallRecordingService.getMsTeamsAccessToken(async (token: string) => {
      var callDetail = await msTeamsCallRecordingService.discardCallRecordingbyCallId(token, callId);
      if(callDetail){
        var _tempCalls = callDetails.map(call => {
          if(call.callId === callId){
            return callDetail;
          }else{
            return call;
          }
        })
        setCallDetails(_tempCalls);
      }
      setLoading(false);
    });
  }

  const FilterCallDetails = (callDetail: MsTeamsCallRecordingDetail, searchString: string) => {
    searchString = searchString.toLocaleLowerCase();
    if(callDetail?.user?.userName?.toLocaleLowerCase().includes(searchString)){
      return true;
    }
    if(callDetail?.participants?.findIndex(p => p?.userName?.toLocaleLowerCase().includes(searchString)) !== -1){
      return true;
    }
    return false;
  }


  const NotClickableHeader = (props: ProductNameHeaderProps) => {
    return (
      <div>
        <span style={{ cursor: "default" }}>{props.title}</span>
      </div>
    );
  };

  const CellCallDateTime = (props: CustomGridCellProps) => {
    const callRecordingDetail = props.dataItem;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn line-height-2">{formatDateTime(callRecordingDetail.startDateTime, "MMM DD, YYYY, HH:mm")}</div>
      </td>
    );
  };
  
  const CellCallUser = (props: CustomGridCellProps) => {
    const userDetail = props.dataItem.user;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn line-height-2">{userDetail.userName ?? userDetail.userId}</div>
      </td>
    );
  };

  const CellCallParticipants = (props: CustomGridCellProps) => {
    const callParticipants = props.dataItem.participants;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn line-height-2">{callParticipants.map((p) => p.userName + ", ")}</div>
      </td>
    );
  };

  const CustomCellWithActions = (props: CustomGridCellProps) => {
    const callRecordingDetail = props.dataItem;

    return (
      <td className="text-right">
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn d-flex justify-content">
            {callRecordingDetail.isDiscarded ? 
              <span
                className="fs-17 line-height-1 text-primary m-l-20"
                title={"Recording Discarded"}
              >
                <i className="bi bi-archive"></i>
              </span>
            :
              <>
                {callRecordingDetail.isPaused ?
                  <Button className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-5 iconBtn`}
                    onClick={() => ResumeCallRecording(callRecordingDetail.callId)}
                  >
                    <span
                      className="fs-17 line-height-1 text-primary"
                      title={"Resume"}
                    >
                      <i className="bi bi-play"></i>
                    </span>
                  </Button>
                  :
                  <Button className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-5 iconBtn`}
                    onClick={() => PauseCallRecording(callRecordingDetail.callId)}
                  >
                    <span
                      className="fs-17 line-height-1 text-primary"
                      title={"Pause"}
                    >
                      <i className="bi bi-pause"></i>
                    </span>
                  </Button>
                }
                <Button className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                  onClick={() => DiscardCallRecording(callRecordingDetail.callId)}
                >
                  <span
                    className="fs-17 line-height-1 text-primary"
                    title={"Discard"}
                  >
                    <i className="bi bi-trash3"></i>
                  </span>
                </Button>
              </>
            }
          </span>
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      <div className="float-left w-100 p-t-20">
        <div className="mobile-contain h-100 pt-4 p-l-15 p-r-15">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12">
                <div className="callListInner">
                  <div className="callListBox">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card cardEffect cardMobile">
                              <div className="card-header d-flex justify-content-between p-0 p-b-12">
                                <span className="fs-13 font-weight-semi d-flex align-items-center">
                                  {`My Active Calls (${callDetails?.filter((cd) => FilterCallDetails(cd, searchTerm))?.length ?? 0}):`}
                                </span>
                                <div className="buttons-container hov-transparent d-flex align-itmes-center">
                                  <div className="searchBox searchCol m-r-10">
                                    <CustomSearchFieldTextInput
                                      className="input-search"
                                      placeholder={"Search…"}
                                      value={searchTerm}
                                      updateValue={setSearchTerm}
                                      searchTextChangeHandler={handleSearchChange}
                                      onEscapeKeyFunc={true}
                                    />
                                  </div>
                                  <Button className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-10 iconBtn`}
                                    onClick={() => getCallsByUserGUID()}
                                  >
                                    <span
                                      className="fs-17 line-height-1 text-primary"
                                      title={"Refresh"}
                                    >
                                      <i className="bi bi-arrow-clockwise"></i>
                                    </span>
                                  </Button>
                                </div>
                              </div>
                              <div className="card-body p-0">
                                <div className="tableList float-left w-100">
                                  <div className="table-container table-responsive table-overflow-hedden highlightRowPlayer borderLeftSecondChild table-mobile">
                                    <Grid
                                      data={callDetails.filter((cd) => FilterCallDetails(cd, searchTerm))}
                                    >
                                      <GridNoRecords>
                                        {loading ? (
                                          <Loader type={"infinite-spinner"} />
                                        ) : ("No Active Calls Available.")}
                                      </GridNoRecords>
                                      <GridColumn
                                        width={200}
                                        field="callTime"
                                        title={"Date/Time"}
                                        cell={CellCallDateTime}
                                        sortable={false}
                                        headerCell={NotClickableHeader}
                                      />
                                      <GridColumn
                                        width={300}
                                        field="user"
                                        title="User"
                                        cell={CellCallUser}
                                        sortable={false}
                                        headerCell={NotClickableHeader}
                                      />
                                      <GridColumn
                                        field="participants"
                                        title={"Participants"}
                                        cell={CellCallParticipants}
                                        sortable={false}
                                        headerCell={NotClickableHeader}
                                      />
                                      <GridColumn
                                        width={150}
                                        field="Actions"
                                        title={"Actions"}
                                        cell={CustomCellWithActions}
                                        sortable={false}
                                        headerCell={NotClickableHeader}
                                      />
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MSTeamsActiveCalls
