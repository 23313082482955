import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import AccountSettings from "./AccountSettings";
import EmailServerSettings from "./EmailServerSettings";
import PlatformLicenses from "./PlatformLicenses";
import SecurityPrivacySettings from "./SecurityPrivacySettings";
import StorageSettings from "./StorageSettings";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useAuth from "../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../enums";
import NotFoundError from "../../error/NotFoundError";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import AuditLogSettings from "./AuditLogSettings";
import RecordingPolicySettings from "./recording-policy/RecordingPolicySettings";
import LoadingOverlay from "../../../components/LoadingOverlay";
import CRMSetting from "./CRMSetting";

interface SystemSettingsProps {}
const SystemSettings: React.FC<SystemSettingsProps> = ({}) => {
  const auth = useAuth();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [selected, setSelected] = useState<number>(0);
  const [pageFreezeLoader, setPageFreezeLoader] = useState<boolean>(false);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["SystemSettings"]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "SystemSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("SystemSettings");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "SystemSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("SystemSettings");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageAccount) ? (
        <UnauthorizedAccess />
      ) : (
        <>
          {pageFreezeLoader && (
            <LoadingOverlay
              customStyle={{
                position: "fixed",
                marginTop: "55px",
                zIndex: "999999",
              }}
              themeColor={"light"}
              size={"medium"}
              loadingText={"Please wait..."}
            />
          )}
          <div className="m-b-20">
            <div className="row">
              <div className="col-md-12">
                <div className="card cardEffect">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="detailHeader d-flex align-items-center">
                      <span className="fs-16 font-weight-semi p-r-20">
                        {`${
                          translationsLoading
                            ? "System Settings"
                            : fetchLabelKeyTranslation(
                                "SystemSettingsTitle",
                                "System Settings"
                              )
                        }`}
                      </span>
                    </span>
                  </div>
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="settingData k-custom-tab p-t-20 p-r-15 p-l-20 p-b-20 systemSet">
                          <TabStrip
                            tabPosition={"left"}
                            selected={selected}
                            onSelect={handleSelect}
                          >
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Account"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsAccountTab",
                                        "Account"
                                      )
                                }`}
                              >
                                <AccountSettings />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Storage"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsStorageTab",
                                        "Storage"
                                      )
                                }`}
                              >
                                <StorageSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "License"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsLicenseTab",
                                        "License"
                                      )
                                }`}
                              >
                                <PlatformLicenses />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Email Account"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsEmailTab",
                                        "Email Account"
                                      )
                                }`}
                              >
                                <EmailServerSettings />
                              </TabStripTab>
                            )}
                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Security & Privacy"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsSecurityTab",
                                        "Security & Privacy"
                                      )
                                }`}
                              >
                                <SecurityPrivacySettings />
                              </TabStripTab>
                            )}

                            {auth?.checkUserAccess(
                              AccessPermissionEnum.ManageAccount
                            ) && (
                              <TabStripTab
                                title={`${
                                  translationsLoading
                                    ? "Audit Logs"
                                    : fetchLabelKeyTranslation(
                                        "SystemSettingsAuditTab",
                                        "Audit Logs"
                                      )
                                }`}
                              >
                                <AuditLogSettings
                                  title={true}
                                  search={true}
                                  filters={true}
                                  excludedColumns={[]}
                                />
                              </TabStripTab>
                            )}
                            <TabStripTab
                              title={`${
                                translationsLoading
                                  ? "Recording Policy"
                                  : fetchLabelKeyTranslation(
                                      "SystemSettingsRecordingPolicyTab",
                                      "Recording Policy"
                                    )
                              }`}
                            >
                              <RecordingPolicySettings
                                setPageFreezeLoader={setPageFreezeLoader}
                              />
                            </TabStripTab>
                            <TabStripTab title="CRM">
                              <CRMSetting />
                            </TabStripTab>
                          </TabStrip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SystemSettings;
