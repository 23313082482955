import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import CustomComboBox from "../../../components/custom/form/CustomComboBox";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import useAuth from "../../../hooks/useAuth";
import useLocale from "../../../hooks/useLocale";
import useMasterData from "../../../hooks/useMasterData";
import customerService from "../../../services/customer.service";
import { Customer } from "../../../types/customer/Customer";
import "./AccountSettings.scss";
import { UpsertCustomer } from "../../../types/customer";
import { Dictionary } from "../../../types/Dictionary";
import useSwal from "../../../hooks/useSwal";
import { Link } from "react-router-dom";

const AccountSettings: React.FC = () => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();
  const [customerInfo, setCustomerInfo] = useState<Customer>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [editing, setEditing] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const formRef = useRef<any>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["AccountSettings"]
  );
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "AccountSettings"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const getCustomerInfo = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId !== undefined && customerId > 0) {
        try {
          setLoading(true);
          const customer = await customerService.getCustomerDetailsById(
            customerId
          );
          setCustomerInfo(customer);
        } catch (err) {
          if (err instanceof AxiosError) {
            console.error(err);
            setError(err.response?.data?.error ?? err.message);
          }
        } finally {
          setLoading(false);
        }
      }
    };

    getCustomerInfo();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("AccountSettings");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "AccountSettings"
        ]
      );
      localeCtx?.setPreviousAppLocale("AccountSettings");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const userEditClickHandler = () => {
    setEditing(true);
  };

  const onUserEditCancelHandler = () => {
    setEditing(false);
    const form = formRef.current as Form;
    form.resetForm();
  };

  const onSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      if (customerInfo) {
        setUpdating(true);
        let updatedCustomer: UpsertCustomer = {
          name: dataItem.customerName,
          email: dataItem.email,
          address: dataItem.customerAddress,
          contact: dataItem.contact,
          timezoneidentifier: dataItem.timezone.displayName,
          localeCode: dataItem.locale.code,
          countryName: dataItem.country.name,
          externalCustomerInfo: customerInfo.externalCustomerInfo,
        };
        const updatedCustomerResponse =
          await customerService.updateCustomerDetailsById(
            customerInfo?.id,
            updatedCustomer
          );
        setCustomerInfo(updatedCustomerResponse);
        setEditing(false);
        swal.fire({
          icon: "success",
          confirmButtonText: `${
            translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwtAltOkText", "Ok")
          }`,
          title: `${
            translationsLoading
              ? "Account Details Updated"
              : fetchLabelKeyTranslation(
                  "SwtAltAccountDetailsUpdated",
                  "Account Details Updated"
                )
          }`,
        });
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        swal.fire({
          icon: "error",
          title: `${
            translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorText", "Error")
          }`,
          text: `${err.message}`,
        });
      }
    } finally {
      setUpdating(false);
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      form.values.locale.id === customerInfo?.locale?.id &&
      (form.values.timezone
        ? form.values.timezone.id === customerInfo?.timezone?.id
        : true) &&
      (form.values.country
        ? form.values.country.id === customerInfo?.country?.id
        : true) &&
      form.values.customerAddress == customerInfo?.address &&
      form.values.contact == customerInfo?.contact &&
      form.values.email == customerInfo?.email &&
      form.values.customerName == customerInfo?.name
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container emlBox">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {translationsLoading
                    ? "Accounts Details"
                    : fetchLabelKeyTranslation(
                        "AccountSettingsTitle",
                        "Accounts Details"
                      )}
                </span>
                {!editing && !error && (
                  <span className="float-right">
                    <Button
                      className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${
                        masterData?.telepoDisableConfig?.systemSetting.account
                          .disableChanges
                          ? "disabledBtn"
                          : "border-primary"
                      }`}
                      style={{ height: "26px" }}
                      onClick={userEditClickHandler}
                      disabled={
                        masterData?.telepoDisableConfig?.systemSetting.account
                          .disableChanges
                      }
                    >
                      <span className="p-r-5">
                        {translationsLoading
                          ? "Edit"
                          : fetchLabelKeyTranslation(
                              "AccountSettingsTabEdit",
                              "Edit"
                            )}
                      </span>
                      <i className="bi bi-pencil"></i>
                    </Button>
                  </span>
                )}
              </div>
              {loading && (
                <div className="m-t-30" style={{ textAlign: "center" }}>
                  <Loader type={"infinite-spinner"} />
                </div>
              )}
              {!loading && error && (
                <div className="float-left w-100 m-t-10">
                  <div className="selectScorecard">
                    <div>
                      <span className="tx-red fs-14">{error}</span>
                    </div>
                  </div>
                </div>
              )}
              {!loading && !error && masterData?.data && (
                <Form
                  onSubmit={onSubmitHandler}
                  ref={formRef}
                  initialValues={{
                    customerName:
                      customerInfo?.externalCustomerInfo?.customerName ??
                      customerInfo?.name,
                    customerAddress: customerInfo?.address,
                    country: masterData?.data?.countries.find(
                      (c) => c.id === customerInfo?.country?.id
                    ),
                    timezone: masterData?.data?.timezones.find(
                      (c) => c.id === customerInfo?.timezone?.id
                    ),
                    locale: customerInfo?.locale,
                    email: customerInfo?.email,
                    contact: customerInfo?.contact,
                  }}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Company Name"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsCompanyName",
                                    "Company Name"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {customerInfo?.externalCustomerInfo
                                    ?.customerName ?? customerInfo?.name}
                                </span>
                              ) : (
                                <Field
                                  className="textInput"
                                  id={"customerName"}
                                  name={"customerName"}
                                  value={formRenderProps.valueGetter(
                                    "customerName"
                                  )}
                                  component={CustomInput}
                                  disabled={
                                    customerInfo?.externalCustomerInfo !== null
                                  }
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsEmail",
                                    "Email"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.email}</span>
                              ) : (
                                <Field
                                  id={"email"}
                                  name={"email"}
                                  className="comboBoxInput"
                                  textField="email"
                                  value={formRenderProps.valueGetter("email")}
                                  component={CustomInput}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Contact"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsContact",
                                    "Contact"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.contact}</span>
                              ) : (
                                <Field
                                  id={"contact"}
                                  name={"contact"}
                                  className="comboBoxInput"
                                  textField="contact"
                                  value={formRenderProps.valueGetter("contact")}
                                  component={CustomInput}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Address"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsAddress",
                                    "Address"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>{customerInfo?.address}</span>
                              ) : (
                                <Field
                                  id={"customerAddress"}
                                  name={"customerAddress"}
                                  style={{ height: "100px" }}
                                  className="comboBoxInput"
                                  placeholder={`${"Customer Address"}`}
                                  max={1000}
                                  autoSize={true}
                                  rows={15}
                                  showTextLimitHint={false}
                                  value={formRenderProps.valueGetter(
                                    "customerAddress"
                                  )}
                                  component={CustomFormTextArea}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Country"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsCountry",
                                    "Country"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {
                                    masterData?.data?.countries.find(
                                      (ct) =>
                                        ct.id === customerInfo?.country?.id
                                    )?.localizationValue
                                  }
                                </span>
                              ) : (
                                <Field
                                  id={"country"}
                                  name={"country"}
                                  className="comboBoxInput"
                                  placeholder="Please select ..."
                                  data={masterData?.data?.countries ?? []}
                                  textField="localizationValue"
                                  value={formRenderProps.valueGetter("country")}
                                  loading={!masterData?.data?.countries}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"localizationValue"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi">
                              {translationsLoading
                                ? "Time Zone"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsTimeZone",
                                    "Time Zone"
                                  )}
                            </div>
                            <div className="trkCol-dot">:</div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {
                                    masterData?.data?.timezones.find(
                                      (tz) =>
                                        tz.id === customerInfo?.timezone?.id
                                    )?.localizationValue
                                  }
                                </span>
                              ) : (
                                <Field
                                  id={"timezone"}
                                  name={"timezone"}
                                  className="comboBoxInput"
                                  placeholder="Please select ..."
                                  data={masterData?.data?.timezones ?? []}
                                  textField="localizationValue"
                                  value={formRenderProps.valueGetter(
                                    "timezone"
                                  )}
                                  loading={!masterData?.data?.timezones}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"localizationValue"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                          <div className="trkCol">
                            <div className="trkCol-h font-weight-semi d-flex align-items-center">
                              {translationsLoading
                                ? "Language"
                                : fetchLabelKeyTranslation(
                                    "AccountSettingsLanguage",
                                    "Language"
                                  )}
                            </div>
                            <div className="trkCol-dot d-flex align-items-center">
                              :
                            </div>
                            <div className="trkCol-p">
                              {!editing ? (
                                <span>
                                  {customerInfo?.locale?.displayName ?? "N/A"}
                                </span>
                              ) : (
                                <Field
                                  id={"locale"}
                                  name={"locale"}
                                  className="comboBoxInput"
                                  data={localeCtx?.supportedLocales ?? []}
                                  textField="displayName"
                                  value={formRenderProps.valueGetter("locale")}
                                  loading={!localeCtx?.supportedLocales}
                                  component={CustomComboBox}
                                  dataItemKey="id"
                                  sorting={true}
                                  sortField={"displayName"}
                                  onChange={compareState}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {editing && (
                          <div className="row p-t-8">
                            <div className="col-md-12 text-right">
                              <Button
                                className="btn bg-black-5 m-r-5"
                                onClick={onUserEditCancelHandler}
                              >
                                {translationsLoading
                                  ? "Cancel"
                                  : fetchLabelKeyTranslation(
                                      "AccountSettingsBtnCancel",
                                      "Cancel"
                                    )}
                              </Button>
                              <Button
                                className={`btn bg-primary text-white ${
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .account.disableChanges
                                    ? "disabledBtn"
                                    : ""
                                }`}
                                disabled={
                                  !formRenderProps.allowSubmit ||
                                  !formRenderProps.modified ||
                                  !formRenderProps.valid ||
                                  buttonStateTracker ||
                                  masterData?.telepoDisableConfig?.systemSetting
                                    .account.disableChanges
                                }
                              >
                                {updating ? (
                                  <Loader
                                    size={"small"}
                                    type={"infinite-spinner"}
                                    themeColor={"light"}
                                  />
                                ) : (
                                  <>
                                    {translationsLoading
                                      ? "Save"
                                      : fetchLabelKeyTranslation(
                                          "AccountSettingsBtnSave",
                                          "Save"
                                        )}
                                  </>
                                )}
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </FormElement>
                  )}
                />
              )}
              <div>
                <Link
                  to="/customer-platform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`btn btn-link text-primary p-0 m-t-20 fs-14 iconBtn`}
                >
                  <i className="bi bi-link-45deg m-r-5"></i>
                  {translationsLoading
                    ? "Customer Platform Detail"
                    : fetchLabelKeyTranslation(
                        "CustomerPlatformDetailLink",
                        "Customer Platform Detail"
                      )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
