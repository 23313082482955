import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useContext, useEffect, useRef, useState } from "react";
import CustomComboBox from "../../../components/custom/form/CustomComboBox";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../components/custom/form/CustomMultiSelect";
import { AccessPermissionEnum } from "../../../enums/accessPermissionEnum";
import useAuth from "../../../hooks/useAuth";
import useLocale from "../../../hooks/useLocale";
import topicsService from "../../../services/topics.service";
import { Dictionary } from "../../../types/Dictionary";
import { Topic } from "../../../types/topic";
import { type } from "os";
import useSwal from "../../../hooks/useSwal";
import useMasterData from "../../../hooks/useMasterData";

interface UpsertTopicDialogProps {
  data?: Topic;
  onCloseDialog: () => void;
  onTopicUpserted: (isUpdated: boolean, upsertedTopic: Topic) => void;
}

const UpsertTopicDialog: React.FC<UpsertTopicDialogProps> = ({
  data,
  onCloseDialog,
  onTopicUpserted,
}) => {
  const auth = useAuth();
  const Swal = useSwal();
  const masterData = useMasterData();
  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  const localeCtx = useLocale();
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertTopicDialog"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertTopicDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertTopicDialog");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertTopicDialog"
    ]
  );

  useEffect(() => {
    fetchTranslations();
  }, [localeCtx?.selectedLocale]);

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>{`${
        translationsLoading
          ? "Enter new keywords…"
          : fetchLabelKeyTranslation("EnterKeyText", "Enter new keywords…")
      }`}</h4>
    );

    return React.cloneElement(element, { ...element.props }, noData);
  };

  const descriptionValidator = (value: string) => {
    if (value.length > 1000) {
      return `${
        translationsLoading
          ? "Description length cannot be more than 1000!"
          : fetchLabelKeyTranslation(
              "DescriptionLengthText",
              "Description length cannot be more than 1000!"
            )
      }`;
    }

    return "";
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Topic Name cannot be empty"
          : fetchLabelKeyTranslation(
              "NameEmptyText",
              "Topic Name cannot be empty"
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "Topic name length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "NameLengthText",
              "Topic name length cannot be more than 100!"
            )
      }`;
    }

    return "";
  };

  const keywordsValidator = (value: string[]) => {
    var keywords = value.map((element) => element.toLocaleLowerCase().trim());

    var hasDuplicate = keywords.some((element, index) => {
      return keywords.indexOf(element) !== index;
    });

    if (!keywords || keywords.length === 0) {
      return `${
        translationsLoading
          ? "Please provide atleast one keyword!"
          : fetchLabelKeyTranslation(
              "OneKeyMsg",
              "Please provide atleast one keyword!"
            )
      }`;
    }
    if (hasDuplicate) {
      return `${
        translationsLoading
          ? "Duplicate keywords not allowed!"
          : fetchLabelKeyTranslation(
              "DuplicateKeyMsg",
              "Duplicate keywords not allowed!"
            )
      }`;
    }
    if (keywords.findIndex((element) => element.length > 50) !== -1) {
      return `${
        translationsLoading
          ? "Keyword length cannot be more than 50!"
          : fetchLabelKeyTranslation(
              "LenKeyMsg",
              "Keyword length cannot be more than 50!"
            )
      }`;
    }
    if (keywords.length > 50) {
      return `${
        translationsLoading
          ? "Keywords cannot be more than 50!"
          : fetchLabelKeyTranslation(
              "LesKeyMsg",
              "Keywords cannot be more than 50!"
            )
      }`;
    }

    return "";
  };

  const visibilityValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Visibility cannot be empty"
          : fetchLabelKeyTranslation("VisMsg", "Visibility cannot be empty")
      }`;
    }

    return "";
  };

  const visibilityOptions = auth?.checkUserAccess(
    AccessPermissionEnum.ManageTopics
  )
    ? [
        `${
          translationsLoading
            ? "PrivateText"
            : fetchLabelKeyTranslation("PrivateText", "Private")
        }`,
        `${
          translationsLoading
            ? "Public"
            : fetchLabelKeyTranslation("PublicText", "Public")
        }`,
      ]
    : [
        `${
          translationsLoading
            ? "PrivateText"
            : fetchLabelKeyTranslation("PrivateText", "Private")
        }`,
      ];

  const submitHandler = () => {
    setError(undefined);
    const form = formRef.current as Form;
    const topic: Topic = {
      id: data?.id ?? 0,
      topicName: form.values.topicName,
      description: form.values.description,
      words: form.values.keywords,
      isPublic: form.values.visiblity === "Public" ? true : false,
      type: form.values.type,
    };
    if (form.isFormValid(form.errors)) {
      setLoading(true);
      topicsService
        .upsertTopic(topic)
        .then((upsertedTopic) => {
          Swal.fire({
            icon: "success",
            title: `${`${
              translationsLoading
                ? "Topic"
                : fetchLabelKeyTranslation("TopicText", "Topic")
            }`} ${
              data
                ? `${
                    translationsLoading
                      ? "Updated"
                      : fetchLabelKeyTranslation("UpdatedMsg", "Updated")
                  }`
                : `${
                    translationsLoading
                      ? "Added"
                      : fetchLabelKeyTranslation("AddedMsg", "Added")
                  }`
            }`,
          });
          onTopicUpserted(data ? true : false, upsertedTopic);
          onCloseDialog();
        })
        .catch((err) => {
          if (err.response.data.error === "Name already exists.") {
            setError(
              `${
                translationsLoading
                  ? "Topic with same name already exists."
                  : fetchLabelKeyTranslation(
                      "InvalidNameTitle",
                      "Topic with same name already exists."
                    )
              }`
            );
          } else {
            setError(
              `${
                translationsLoading
                  ? "Something went wrong! Please try again"
                  : fetchLabelKeyTranslation(
                      "SomethingError",
                      "Something went wrong! Please try again"
                    )
              }`
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(
        `${
          translationsLoading
            ? "Please check all the fields!"
            : fetchLabelKeyTranslation(
                "CheckError",
                "Please check all the fields!"
              )
        }`
      );
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;
    console.log(form.values.keywords);

    if (
      compareArray(
        data?.words ? data?.words.slice() : [],
        form.values.keywords.slice()
      ) &&
      JSON.stringify(data?.topicName) ==
        JSON.stringify(form.values.topicName) &&
      JSON.stringify(data?.description) ==
        JSON.stringify(form.values.description) &&
      JSON.stringify(data?.isPublic) ==
        JSON.stringify(form.values.isPublic) &&
      JSON.stringify(data?.type) ==
        JSON.stringify(form.values.type)
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const compareArray = (array1: any[], array2: any[]): boolean => {
    array1.sort();
    array2.sort();

    if (array1.length != array2.length) return false;
    else {
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] != array2[i]) return false;
      }
      return true;
    }
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        topicName: data?.topicName ?? "",
        description: data?.description ?? "",
        keywords: data?.words ?? [],
        visiblity: data?.isPublic ? visibilityOptions[1] : visibilityOptions[0],
        type: data?.type
          ? masterData?.data?.topicType.find((tt) => tt.id === data.type.id)
          : masterData?.data?.topicType.find((tt) => tt.id === 2),
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              data
                ? `${
                    translationsLoading
                      ? "Edit Topic"
                      : fetchLabelKeyTranslation("EditTopicTitle", "Edit Topic")
                  }`
                : `${
                    translationsLoading
                      ? "Add Topic"
                      : fetchLabelKeyTranslation("AddTopicTitle", "Add Topic")
                  }`
            }
            onClose={onCloseDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Name"
                    : fetchLabelKeyTranslation("NameTitle", "Name")
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="topicName"
                      name="topicName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("topicName")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter topic name…"
                          : fetchLabelKeyTranslation(
                              "NamePC",
                              "Enter topic name…"
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Description"
                    : fetchLabelKeyTranslation(
                        "DescriptionTitle",
                        "Description"
                      )
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id={"description"}
                      name={"description"}
                      style={{
                        minWidth: "250px",
                        height: "auto",
                        minHeight: "70px",
                      }}
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      placeholder={`${
                        translationsLoading
                          ? "Enter topic description…"
                          : fetchLabelKeyTranslation(
                              "DescriptionPC",
                              "Enter topic description…"
                            )
                      }`}
                      value={formRenderProps.valueGetter("description")}
                      component={CustomFormTextArea}
                      validator={descriptionValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Keywords"
                    : fetchLabelKeyTranslation("KeywordsTitle", "Keywords")
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="keywords"
                      name="keywords"
                      placeholder={`${
                        translationsLoading
                          ? "Enter Keywords…"
                          : fetchLabelKeyTranslation(
                              "KeywordsPC",
                              "Enter Keywords…"
                            )
                      }`}
                      allowCustom={true}
                      listNoDataRender={listNoDataRender}
                      value={formRenderProps.valueGetter("keywords")}
                      component={CustomMultiSelect}
                      validator={keywordsValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-10 d-flex align-items-center justify-content-between">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Type"
                    : fetchLabelKeyTranslation("TypeTitle", "Type")
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput remove-cross">
                    <Field
                      id="type"
                      name="type"
                      style={{ height: "32px" }}
                      data={masterData?.data?.topicType}
                      value={formRenderProps.valueGetter("type")}
                      component={CustomComboBox}
                      dataItemKey="id"
                      textField="localizationValue"
                      disabled={data}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-10 d-flex align-items-center justify-content-between">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Visibility"
                    : fetchLabelKeyTranslation("VisibilityTitle", "Visibility")
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput remove-cross">
                    <Field
                      id="visibility"
                      name="visiblity"
                      style={{ height: "32px" }}
                      data={visibilityOptions}
                      value={formRenderProps.valueGetter("visibility")}
                      component={CustomComboBox}
                      validator={visibilityValidator}
                      disabled={data?.isPublic}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button className={`btn bg-black-5`} onClick={onCloseDialog}>
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelButton", "Cancel")
                }`}
              </Button>
              <Button
                className={` ${
                  "k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white" +
                  ((
                    (!formRenderProps.valid || buttonStateTracker) && data
                      ? true
                      : false
                  )
                    ? "disabledBtn"
                    : "")
                }`}
                onClick={submitHandler}
                disabled={
                  (!formRenderProps.valid || buttonStateTracker) && data
                    ? true
                    : false
                }
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    translationsLoading
                      ? "Save"
                      : fetchLabelKeyTranslation("SaveButtonText", "Save")
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertTopicDialog;
