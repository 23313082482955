import React, { useContext, useEffect, useState } from "react";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { SortDescriptor, orderBy, process } from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn,
  GridCellProps,
  GridNoRecords,
  GridSortChangeEvent,
  GridHeaderCellProps,
} from "@progress/kendo-react-grid";
import UpsertTopicDialog from "./UpsertTopicDialog";
import { Topic } from "../../../types/topic";
import { Loader } from "@progress/kendo-react-indicators";
import { convertToPascalCase } from "../../../utils/stringUtils";
import { AccessPermissionEnum } from "../../../enums/accessPermissionEnum";
import useAuth from "../../../hooks/useAuth";
import topicsService from "../../../services/topics.service";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import { type } from "os";
import { Tooltip } from "@progress/kendo-react-tooltip";
import NotFoundError from "../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import useMasterData from "../../../hooks/useMasterData";

interface CustomGridCellProps extends GridCellProps {
  dataItem: Topic;
}

interface ProductNameHeaderProps extends GridHeaderCellProps {
  children: any;
}

const CustomCellTopicName = (props: CustomGridCellProps) => {
  const topic = props.dataItem;
  return (
    <td className="uCardCol">
      <div className="tblUsr">
        <div className="topUsrAreaPic">
          <div className="topUsrAreaPic-tx">
            <span className="text-primary font-weight-bold cursor-default text-decoration-none float-left w-100 line-height-1">
              {convertToPascalCase(topic.topicName)}
            </span>
          </div>
        </div>
      </div>
    </td>
  );
};
const CelltopicDescription = (props: CustomGridCellProps) => {
  const topic = props.dataItem;
  return (
    <td className="mx-th-tag">
      <div className="mx-td-spn line-height-2">{topic.description}</div>
    </td>
  );
};
const CellWithCallTopics = (props: CustomGridCellProps) => {
  const topic = props.dataItem;
  return (
    <td className="mx-th-tag">
      <div className="mx-td-spn">
        {topic.isSystemDefined ?
          <div className="keywordRow blurArea4">
            <div className="keywordTrk">
              System Defined Topic
            </div>
          </div>
        :
          <div className="keywordRow">
            {topic.words.map((kw) => {
              return (
                <div key={kw} className="keywordTrk">
                  {kw}
                </div>
              );
            })}
          </div>
        }
      </div>
    </td>
  );
};

const Topics: React.FC = () => {
  const auth = useAuth();
  const masterData = useMasterData();
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const [page, setPage] = useState(initialDataState);
  const handlePageChange = (event: any) => {
    setPage(event.page);
  };

  const localeCtx = useLocale();
  const Swal = useSwal();
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("Topics");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["Topics"]
      );
      localeCtx?.setPreviousAppLocale("Topics");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["Topics"]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Topics"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const [visible, setVisible] = React.useState<boolean>(false);
  const toggleDialog = () => {
    setEditTopic(undefined);
    setVisible(!visible);
  };

  const [topics, setTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [editTopic, setEditTopic] = useState<Topic | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sort, setSort] = useState([] as SortDescriptor[]);

  const CellTopicVisibility = (props: CustomGridCellProps) => {
    const topic = props.dataItem;
    return (
      <td>
        <span
          className="fs-16 text-black-10 m-r-10"
          title={
            topic.isPublic
              ? `${
                  translationsLoading
                    ? "Public"
                    : fetchLabelKeyTranslation("PublicTitle", "Public")
                }`
              : `${
                  translationsLoading
                    ? "Private"
                    : fetchLabelKeyTranslation("PrivateTitle", "Private")
                }`
          }
        >
          {
            <i
              className={`bi ${topic.isPublic ? "bi-people" : "bi-lock-fill"}`}
            ></i>
          }
        </span>
        <span className="fs-14">
          {topic.isPublic
            ? `${
                translationsLoading
                  ? "Public"
                  : fetchLabelKeyTranslation("PublicTitle", "Public")
              }`
            : `${
                translationsLoading
                  ? "Private"
                  : fetchLabelKeyTranslation("PrivateTitle", "Private")
              }`}
        </span>
      </td>
    );
  };

  const CellTopicType = (props: CustomGridCellProps) => {
    const topic = props.dataItem;
    return (
      <td>
        <span>{topic?.type ? masterData?.data?.topicType.find(tt => tt.id === topic.type.id)?.localizationValue : ""}</span>
      </td>
    );
  };

  const CustomCellWithActions = (props: CustomGridCellProps) => {
    const topic = props.dataItem;
    const hasTopicManagementPermission = auth?.checkUserAccess(
      AccessPermissionEnum.ManageTopics
    );
    const onEditHandler = () => {
      setEditTopic(topic);
      setVisible(true);
    };

    const onDeleteHandler = () => {
      Swal.fire({
        title: `${
          translationsLoading
            ? "Are you sure?"
            : fetchLabelKeyTranslation("WarningTitle", "Are you sure?")
        }`,
        text: `${
          translationsLoading
            ? "You won't be able to revert this!"
            : fetchLabelKeyTranslation(
                "WarningText",
                "You won't be able to revert this!"
              )
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Yes, delete it!"
            : fetchLabelKeyTranslation("ConfirmButtonText", "Yes, delete it!")
        }`,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelButton", "Cancel")
        }`,
      }).then((result) => {
        if (result.isConfirmed) {
          topicsService
            .deleteTopic(topic.id)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: `${
                  translationsLoading
                    ? "Topic Deleted!"
                    : fetchLabelKeyTranslation(
                        "TopicDeletedTitle",
                        "Topic Deleted!"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Topic has been deleted."
                    : fetchLabelKeyTranslation(
                        "TopicDeletedText",
                        "Topic has been deleted."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
              const updatedTopics = topics?.filter((t) => t.id !== topic.id);
              setTopics(updatedTopics);
              const gridDataResult = process(
                updatedTopics.filter((t) => searchTopics(t)),
                page
              );
              if (
                gridDataResult.data.length === 0 &&
                gridDataResult.total > 0
              ) {
                const currentPageNumber = Math.floor(
                  gridDataResult.total / page.take
                );
                setPage({
                  skip:
                    gridDataResult.total % page.take === 0
                      ? (currentPageNumber - 1) * page.take
                      : currentPageNumber * page.take,
                  take: page.take,
                });
              }
            })
            .catch((err) => {
              if (err instanceof Error) {
                Swal.fire({
                  icon: "error",
                  title: `${
                    translationsLoading
                      ? "Error"
                      : fetchLabelKeyTranslation("ErrorTitle", "Error")
                  }`,
                  text: `${
                    translationsLoading
                      ? "Could not delete topic."
                      : fetchLabelKeyTranslation(
                          "DeleteErrorText",
                          "Could not delete topic."
                        )
                  }`,
                  confirmButtonText: `${
                    translationsLoading
                      ? "OK"
                      : fetchLabelKeyTranslation("OKText", "OK")
                  }`,
                });
              }
            });
        }
      });
    };

    return (
      <td className="text-right">
        <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
          <span className="iBtn d-flex justify-content">
            {topic.isSystemDefined && 
              <Button
              className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn cursor-default`}
            >
              <span
                className="fs-17 line-height-1 text-primary"
                title={`${
                  translationsLoading
                    ? "System Defined"
                    : fetchLabelKeyTranslation("SystemDefinedIconTitle", "System Defined")
                }`}
              >
                <i className="bi bi-lock"></i>
              </span>
            </Button>
            }
            {!topic.isSystemDefined && 
              <>
                <Button
                  className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                  onClick={onEditHandler}
                  disabled={!hasTopicManagementPermission && topic.isPublic}
                  >
                  <span
                    className="fs-17 line-height-1 text-primary"
                    title={`${
                      translationsLoading
                      ? "Edit"
                      : fetchLabelKeyTranslation("EditButton", "Edit")
                    }`}
                    >
                    <i className="bi bi-pencil"></i>
                  </span>
                </Button>
                <Button
                  className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn`}
                  onClick={onDeleteHandler}
                  disabled={!hasTopicManagementPermission && topic.isPublic}
                  >
                  <span
                    className="fs-17 line-height-1 text-primary"
                    title={`${
                      translationsLoading
                      ? "Delete"
                      : fetchLabelKeyTranslation("DeleteButton", "Delete")
                    }`}
                    >
                    <i className="bi bi-trash3"></i>
                  </span>
                </Button>
              </>
            }
          </span>
        </Tooltip>
      </td>
    );
  };

  const customCellMobile = (props: CustomGridCellProps) => {
    const topic = props.dataItem;
    const hasTopicManagementPermission = auth?.checkUserAccess(
      AccessPermissionEnum.ManageTopics
    );
    const onEditHandler = () => {
      setEditTopic(topic);
      setVisible(true);
    };

    const onDeleteHandler = () => {
      Swal.fire({
        title: `${
          translationsLoading
            ? "Are you sure?"
            : fetchLabelKeyTranslation("WarningTitle", "Are you sure?")
        }`,
        text: `${
          translationsLoading
            ? "You won't be able to revert this!"
            : fetchLabelKeyTranslation(
                "WarningText",
                "You won't be able to revert this!"
              )
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Yes, delete it!"
            : fetchLabelKeyTranslation("ConfirmButtonText", "Yes, delete it!")
        }`,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelButton", "Cancel")
        }`,
      }).then((result) => {
        if (result.isConfirmed) {
          topicsService
            .deleteTopic(topic.id)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: `${
                  translationsLoading
                    ? "Topic Deleted!"
                    : fetchLabelKeyTranslation(
                        "TopicDeletedTitle",
                        "Topic Deleted!"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Topic has been deleted."
                    : fetchLabelKeyTranslation(
                        "TopicDeletedText",
                        "Topic has been deleted."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
              const updatedTopics = topics?.filter((t) => t.id !== topic.id);
              setTopics(updatedTopics);
              const gridDataResult = process(
                updatedTopics.filter((t) => searchTopics(t)),
                page
              );
              if (
                gridDataResult.data.length === 0 &&
                gridDataResult.total > 0
              ) {
                const currentPageNumber = Math.floor(
                  gridDataResult.total / page.take
                );
                setPage({
                  skip:
                    gridDataResult.total % page.take === 0
                      ? (currentPageNumber - 1) * page.take
                      : currentPageNumber * page.take,
                  take: page.take,
                });
              }
            })
            .catch((err) => {
              if (err instanceof Error) {
                Swal.fire({
                  icon: "error",
                  title: `${
                    translationsLoading
                      ? "Error"
                      : fetchLabelKeyTranslation("ErrorTitle", "Error")
                  }`,
                  text: `${
                    translationsLoading
                      ? "Could not delete topic."
                      : fetchLabelKeyTranslation(
                          "DeleteErrorText",
                          "Could not delete topic."
                        )
                  }`,
                  confirmButtonText: `${
                    translationsLoading
                      ? "OK"
                      : fetchLabelKeyTranslation("OKText", "OK")
                  }`,
                });
              }
            });
        }
      });
    };

    return (
      <td className="listMobile">
        <div className="listRowMobile">
          <div className="tblUsr float-left w-100 p-r-40 p-b-15 p-l-10">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-tx">
                <span className="fs-14 font-weight-semi line-height-2">
                  {`${
                    translationsLoading
                      ? "Topic"
                      : fetchLabelKeyTranslation("TopicText", "Topic")
                  }`}
                </span>
                <span className="text-primary fs-17 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2">
                  {convertToPascalCase(topic.topicName)}
                </span>
              </div>
            </div>
          </div>
          <div className="topics float-left w-100 p-t-0 p-b-10 p-l-10">
            <div className="fs-14 font-weight-semi p-r-5">
              {`${
                translationsLoading
                  ? "Description"
                  : fetchLabelKeyTranslation("DescriptionTitle", "Description")
              }`}
              :
            </div>
            <div className="chipLable line-height-2">
              <div className="mx-th-tag">
                <div className="mx-td-spn line-height-2">
                  {topic.description}
                </div>
              </div>
            </div>
          </div>
          <div className="topics float-left w-100 p-t-5 p-b-8 p-l-10">
            <div className="fs-14 font-weight-semi p-r-15">
              {`${
                translationsLoading
                  ? "Keywords"
                  : fetchLabelKeyTranslation("KeywordsTitle", "Keywords")
              }`}
              :
            </div>
            <div className="chipLable">
              <div className="mx-th-tag">
                <div className="mx-td-spn">
                  <div className="keywordRow">
                    {topic.words.map((kw) => {
                      return (
                        <div key={kw} className="keywordTrk">
                          {kw}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="topics float-left w-100 p-t-5 p-l-10 d-flex align-items-center">
            <div className="fs-14 font-weight-semi p-r-10">
              {`${
                translationsLoading
                  ? "Visibility"
                  : fetchLabelKeyTranslation("VisibilityTitle", "Visibility")
              }`}
              :
            </div>
            <div className="chipLable">
              <span
                className="fs-16 text-black-10 m-r-10"
                title={
                  topic.isPublic
                    ? `${
                        translationsLoading
                          ? "Public"
                          : fetchLabelKeyTranslation("PublicTitle", "Public")
                      }`
                    : `${
                        translationsLoading
                          ? "Private"
                          : fetchLabelKeyTranslation("PrivateTitle", "Private")
                      }`
                }
              >
                {
                  <i
                    className={`bi ${
                      topic.isPublic ? "bi-people" : "bi-lock-fill"
                    }`}
                  ></i>
                }
              </span>
              <span className="fs-14">
                {topic.isPublic
                  ? `${
                      translationsLoading
                        ? "Public"
                        : fetchLabelKeyTranslation("PublicTitle", "Public")
                    }`
                  : `${
                      translationsLoading
                        ? "Private"
                        : fetchLabelKeyTranslation("PrivateTitle", "Private")
                    }`}
              </span>
            </div>
          </div>
          <div className="iconMobileDetail">
            {topic.isSystemDefined && 
              <span
                className="fs-18 text-primary"
                title={`${
                translationsLoading
                  ? "System Defined"
                  : fetchLabelKeyTranslation("SystemDefinedIconTitle", "System Defined")
                }`}
              >
                <button
                  className="btn btn-link text-primary p-r-4 p-l-4 m-0 fs-18"
                >
                  <i className="bi bi-lock"></i>
                </button>
             </span>
           }
           {!topic.isSystemDefined &&
            <>
              <span
                className="fs-18 text-primary"
                title={`${
                  translationsLoading
                    ? "Edit"
                    : fetchLabelKeyTranslation("EditButton", "Edit")
                }`}
              >
                <button
                  className="btn btn-link text-primary p-r-4 p-l-4 m-0 fs-18 cursor-pointer"
                  onClick={onEditHandler}
                  disabled={!hasTopicManagementPermission && topic.isPublic}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </span>
              <span
                className="fs-18 text-primary"
                title={`${
                  translationsLoading
                    ? "Delete"
                    : fetchLabelKeyTranslation("DeleteButton", "Delete")
                }`}
              >
                <button
                  className="btn btn-link text-primary p-r-4 p-l-4 m-0 fs-18 cursor-pointer"
                  onClick={onDeleteHandler}
                  disabled={!hasTopicManagementPermission && topic.isPublic}
                >
                  <i className="bi bi-trash3"></i>
                </button>
              </span>
            </>
           }

          </div>
        </div>
      </td>
    );
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setLoading(true);
        const data = await topicsService.fetchAllTopics();
        setTopics(data);
      } catch (ex) {
        if (ex instanceof Error) {
          setError(ex.message);
        }
      } finally {
        setLoading(false);
      }
    };

    sendRequest();
  }, []);

  const onTopicUpserted = (isUpdated: boolean, upsertedTopic: Topic) => {
    if (isUpdated) {
      const updatedTopics: Topic[] = [];
      topics?.forEach((t) => {
        if (t.id === upsertedTopic.id) {
          updatedTopics.push(upsertedTopic);
        } else {
          updatedTopics.push(t);
        }
      });
      setTopics(updatedTopics);
    } else {
      const updatedTopics = [...topics, upsertedTopic];
      setTopics(updatedTopics);
      if (updatedTopics.length % page.take !== 0) {
        setPage({
          skip: Math.floor(updatedTopics.length / page.take) * page.take,
          take: page.take,
        });
      }
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
    setPage(initialDataState);
  };

  const searchTopics = (topic: Topic) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    if (topic.topicName?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    if (topic.description?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    if (
      (masterData?.data?.topicType.find(tt => tt.id === topic.type.id)?.localizationValue ?? "").toLocaleLowerCase()
        .includes(searchVal)
    ) {
      return true;
    }
    if ("public".includes(searchVal) && topic.isPublic) {
      return true;
    }
    if ("private".includes(searchVal) && !topic.isPublic) {
      return true;
    }

    return false;
  };

  const NotClickableHeader = (props: ProductNameHeaderProps) => {
    return (
      <div>
        <span style={{ cursor: "default" }}>{props.title}</span>
      </div>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageTopics) ||
      !auth?.checkTopicAvailibility() ? (
        <UnauthorizedAccess />
      ) : (
        <div className="callListInner">
          <div className="callListBox">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card cardEffect cardMobile">
                      <div className="card-header d-flex justify-content-between p-0 p-b-12">
                        <span className="fs-13 font-weight-semi d-flex align-items-center">
                          {`${
                            translationsLoading
                              ? "Topics"
                              : fetchLabelKeyTranslation(
                                  "TopicsTitle",
                                  "Topics"
                                )
                          }`}
                        </span>
                        <div className="buttons-container hov-transparent d-flex align-itmes-center">
                          <div className="searchBox searchCol m-r-10">
                            <CustomSearchFieldTextInput
                              className="input-search"
                              placeholder={`${
                                translationsLoading
                                  ? "Search…"
                                  : fetchLabelKeyTranslation(
                                      "SearchBoxPlaceHolder",
                                      "Search…"
                                    )
                              }`}
                              value={searchTerm}
                              updateValue={setSearchTerm}
                              searchTextChangeHandler={handleSearchChange}
                              onEscapeKeyFunc={true}
                            />
                          </div>

                          <Button
                            onClick={toggleDialog}
                            className={
                              "btn bg-primary text-white fs-13 fw-normal"
                            }
                            style={{ height: "29px", margin: "-1px 0 0 0" }}
                            title={`${
                              translationsLoading
                                ? "Add"
                                : fetchLabelKeyTranslation("AddButton", "Add")
                            }`}
                          >
                            {translationsLoading
                              ? "Add"
                              : fetchLabelKeyTranslation("AddButton", "Add")}
                          </Button>

                          {visible && (
                            <UpsertTopicDialog
                              onCloseDialog={toggleDialog}
                              data={editTopic}
                              onTopicUpserted={onTopicUpserted}
                            />
                          )}
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="tableList float-left w-100">
                          <div className="table-container table-responsive table-overflow-hedden highlightRowPlayer borderLeftSecondChild table-mobile">
                            <Grid
                              data={orderBy(
                                topics
                                  .filter((t) => searchTopics(t))
                                  .slice(page.skip, page.take + page.skip) ??
                                  [],
                                sort
                              )}
                              skip={page.skip}
                              take={page.take}
                              total={
                                topics?.filter((t) => searchTopics(t)).length
                              }
                              pageable={{ buttonCount: 4, pageSizes: true }}
                              onPageChange={handlePageChange}
                              expandField="expanded"
                              sortable={true}
                              sort={sort}
                              onSortChange={(e: GridSortChangeEvent) => {
                                console.log("sort", e);
                                let tempSort = e.sort;
                                if (e.sort.length === 0 && sort.length !== 0) {
                                  tempSort = sort.map((s) => {
                                    return { field: s.field, dir: "asc" };
                                  });
                                }
                                setTopics(
                                  orderBy(
                                    topics?.filter((el) => searchTopics(el)) ??
                                      [],
                                    tempSort
                                  )
                                );
                                setSort(tempSort);
                              }}
                            >
                              <GridNoRecords>
                                {loading ? (
                                  <Loader type={"infinite-spinner"} />
                                ) : (
                                  `${
                                    translationsLoading
                                      ? "No Records Available."
                                      : fetchLabelKeyTranslation(
                                          "NoRecordText",
                                          "No Records Available."
                                        )
                                  }`
                                )}
                              </GridNoRecords>
                              <GridColumn
                                width={0}
                                field="cellMobile"
                                cell={customCellMobile}
                              />
                              <GridColumn
                                width={300}
                                field="topicName"
                                title={`${
                                  translationsLoading
                                    ? "Name"
                                    : fetchLabelKeyTranslation(
                                        "NameTitle",
                                        "Name"
                                      )
                                }`}
                                cell={CustomCellTopicName}
                              />
                              <GridColumn
                                field="description"
                                title={`${
                                  translationsLoading
                                    ? "Description"
                                    : fetchLabelKeyTranslation(
                                        "DescriptionTitle",
                                        "Description"
                                      )
                                }`}
                                cell={CelltopicDescription}
                                sortable={false}
                                headerCell={NotClickableHeader}
                              />
                              <GridColumn
                                field="topicKey"
                                title={`${
                                  translationsLoading
                                    ? "Keywords"
                                    : fetchLabelKeyTranslation(
                                        "KeywordsTitle",
                                        "Keywords"
                                      )
                                }`}
                                cell={CellWithCallTopics}
                                sortable={false}
                                headerCell={NotClickableHeader}
                              />
                              <GridColumn
                                width={150}
                                field="isPublic"
                                title={`${
                                  translationsLoading
                                    ? "Visibility"
                                    : fetchLabelKeyTranslation(
                                        "VisibilityTitle",
                                        "Visibility"
                                      )
                                }`}
                                cell={CellTopicVisibility}
                              />
                              <GridColumn
                                width={150}
                                field="type.name"
                                title={`${
                                  translationsLoading
                                    ? "Type"
                                    : fetchLabelKeyTranslation(
                                        "TypeTitle",
                                        "Type"
                                      )
                                }`}
                                cell={CellTopicType}
                              />
                              <GridColumn
                                width={150}
                                field="topicActions"
                                title={`${
                                  translationsLoading
                                    ? "Actions"
                                    : fetchLabelKeyTranslation(
                                        "ActionsTitle",
                                        "Actions"
                                      )
                                }`}
                                cell={CustomCellWithActions}
                                sortable={false}
                                headerCell={NotClickableHeader}
                              />
                            </Grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Topics;
