import { Button } from "@progress/kendo-react-buttons";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { ListItemProps, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import CustomMultiSelect from "../../components/custom/form/CustomMultiSelect";
import useMasterData from "../../hooks/useMasterData";
import { TinyUser } from "../../types/user";
import MsTeamsEmailSharingPopup from "./MsTeamsEmailSharingPopup";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import Swal from "sweetalert2";
import { PlatformDetail } from "../../types/customer";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import React from "react";

interface MsTeamsProps {
  platformDetail: PlatformDetail
}

const MsTeams: React.FC<MsTeamsProps> = ({platformDetail}) => {
  const masterData = useMasterData();
  const localeCtx = useLocale();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "CustomerPlatformMsTeams"
    ]
  );

  const [recordingPolicyCreationScript, setRecordingPolicyCreationScript] =
    useState<string>("");
  const [
    assigningRecordingPolicyToUsersScript,
    setAssigningRecordingPolicyToUsersScript,
  ] = useState<string>("");
  const [
    removingRecordingPolicyFromUsersScript,
    setRemovingRecordingPolicyFromUsersScript,
  ] = useState<string>("");
  const [assignedUsers, setAssignedUsers] = useState<TinyUser[]>([]);
  const [removedUsers, setRemovedUsers] = useState<TinyUser[]>([]);
  const [emailSharingPopup, setEmailSharingPopup] = useState<boolean>(false);
  const [emailPolicyScript, setEmailPolicyScript] = useState<string>("");
  const [emailPolicyScriptTemplate, setEmailPolicyScriptTemplate] = useState<string>("")

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "CustomerPlatformMsTeams"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    fetchRecordingPolicyCreationScript();
    fetchAssigningRecordingPolicyToUsersScript();
    fetchRemovingRecordingPolicyFromUsersScript();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "CustomerPlatformMsTeams"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "CustomerPlatformMsTeams"
        ]
      );
      localeCtx?.setPreviousAppLocale("CustomerPlatformMsTeams");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const fetchRecordingPolicyCreationScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/RecordingPolicyCreationScript.txt"
    );
    const fetchedRecordingPolicyCreationScript = await response.text();
    setRecordingPolicyCreationScript(fetchedRecordingPolicyCreationScript);
  };

  const fetchAssigningRecordingPolicyToUsersScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/AssigningRecordingPolicyToUsersScript.txt"
    );
    const fetchedAssigningRecordingPolicyToUsersScript = await response.text();
    setAssigningRecordingPolicyToUsersScript(
      fetchedAssigningRecordingPolicyToUsersScript
    );
  };

  const fetchRemovingRecordingPolicyFromUsersScript = async () => {
    const response = await fetch(
      process.env.PUBLIC_URL + "/msTeamsFiles/RemovingRecordingPolicyFromUsersScript.txt"
    );
    const fetchedRemovingRecordingPolicyFromUsersScript = await response.text();
    setRemovingRecordingPolicyFromUsersScript(
      fetchedRemovingRecordingPolicyFromUsersScript
    );
  };

  const getUpdatedAssigningRecordingPolicyToUsersScript = () => {
    return assigningRecordingPolicyToUsersScript.replace(`"<username@domain.com>"`, assignedUsers.map(u => `"` + u.email + `"`).join(', '));
  }

  const getUpdatedRemovingRecordingPolicyFromUsersScript = () => {
    return removingRecordingPolicyFromUsersScript.replace(`"<username@domain.com>"`, removedUsers.map(u => `"` + u.email + `"`).join(', '));
  }

  const downloadScript = (filename: string, text: string) => {
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const onClickEmail = (policyScript: string, emailTemplate: string) => {
    setEmailPolicyScript(policyScript);
    setEmailPolicyScriptTemplate(emailTemplate);
    setEmailSharingPopup(true);
  };

  const onCloseEmail = () => {
    setEmailPolicyScript("");
    setEmailPolicyScriptTemplate("");
    setEmailSharingPopup(false);
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: 0,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div>
      {emailSharingPopup && (
        <MsTeamsEmailSharingPopup
          policyScript={emailPolicyScript}
          emailTemplate={emailPolicyScriptTemplate} 
          onCloseEmail={onCloseEmail} 
        />
      )}
      <div className="trk-row border-bottom-solid border-w-1 border-black-1">
        <div className="trkCol">
          <div className="trkCol-h font-weight-semi">{`${
            translationsLoading
              ? "Tenant Id"
              : fetchLabelKeyTranslation("FieldTenantId", "Tenant Id")
          }`}</div>
          <div className="trkCol-dot">:</div>
          <div className="trkCol-p">{platformDetail.customerDetail.msTeamsTenantsGuid}</div>
        </div>
      </div>
      <div className="trk-row border-bottom-solid border-w-1 border-black-1">
        <div className="trkCol">
          <div className="trkCol-h font-weight-semi">{`${
            translationsLoading
              ? "Admin Email"
              : fetchLabelKeyTranslation("FieldAdminEmail", "Admin Email")
          }`}</div>
          <div className="trkCol-dot">:</div>
          <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmailAddress}</div>
        </div>
      </div>
      <div className="accordionData float-left w-100 p-t-30">
        <span className="text-primary font-weight-semi fs-14 p-b-10 float-left w-100 d-flex align-items-center">
          <i className="bi bi-microsoft-teams fs-20 p-r-5"></i>
          <span>{`${
            translationsLoading
              ? "Recording Bot Policy"
              : fetchLabelKeyTranslation(
                  "HeadingRecordingBotPolicy",
                  "Recording Bot Policy"
                )
          }`}</span>
        </span>
        <div className="panelbar-wrapper float-left w-100">
          <PanelBar animation={true}>
            <PanelBarItem
              title={`${
                translationsLoading
                  ? "Create Recording Policy"
                  : fetchLabelKeyTranslation(
                      "HeadingCreateRecordingPolicy",
                      "Create Recording Policy"
                    )
              }`}
              expanded={true}
              icon="folder"
            >
              <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                <div className="visibleDataDiv float-left w-100">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="topCodeBox float-left w-100">
                        <div className="dropBox float-left">
                          <div className="dropBox"></div>
                        </div>
                        <div className="codeBoxActionBtn float-right">
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                          >
                            <span
                              onClick={() =>
                                downloadScript(
                                  "RecordingPolicyCreationScript.ps1",
                                  recordingPolicyCreationScript
                                )
                              }
                              className="p-r-2"
                            >
                              {translationsLoading
                                ? "Download"
                                : fetchLabelKeyTranslation(
                                    "BtnDownload",
                                    "Download"
                                  )}
                            </span>
                            <i className="bi bi-download"></i>
                          </Button>
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                            onClick={() => onClickEmail(recordingPolicyCreationScript, "RecordingPolicyCreation")}
                          >
                            <span className="p-r-2">
                              {translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation("BtnEmail", "Email")}
                            </span>
                            <i className="bi bi-envelope"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="codeData">
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              recordingPolicyCreationScript
                            );
                          }}
                          className="copCodeDataBtn"
                        >
                          <span>
                            {translationsLoading
                              ? "Copy Code"
                              : fetchLabelKeyTranslation(
                                  "BtnCopyCode",
                                  "Copy Code"
                                )}
                          </span>
                          <i className="bi bi-files p-l-3"></i>
                        </Button>
                        <div
                          className="fs-13"
                          style={{
                            maxHeight: "200px",
                            overflow: "auto",
                            margin: "0 0 10px 0",
                          }}
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {recordingPolicyCreationScript}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PanelBarItem>
            <PanelBarItem
              title={`${
                translationsLoading
                  ? "Assign Recording Policy"
                  : fetchLabelKeyTranslation(
                      "HeadingAssignRecordingPolicy",
                      "Assign Recording Policy"
                    )
              }`}
              animation={true}
              icon="folder"
            >
              <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                <div className="visibleDataDiv float-left w-100">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="topCodeBox float-left w-100">
                        <div className="dropBox float-left">
                          <div className="dropBox">
                            <div className="dropBoxH p-r-5">
                              {translationsLoading
                                ? "Select User"
                                : fetchLabelKeyTranslation(
                                    "FieldSelectUser",
                                    "Select User"
                                  )}
                            </div>
                            <div className="dropBoxInput">
                              <CustomMultiSelect
                                data={masterData?.users?.filter((user: TinyUser) => !user.isArchived)}
                                onChange={(event: MultiSelectChangeEvent) =>
                                  setAssignedUsers([...event.value])
                                }
                                value={assignedUsers}
                                dataItemKey="id"
                                itemRender={itemRenderUser}
                                textField="email"
                                filtering={true}
                                placeholder={
                                  translationsLoading
                                    ? "Please select..."
                                    : fetchLabelKeyTranslation(
                                        "PlaceholderMultiSelectOne",
                                        "Please select..."
                                      )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="codeBoxActionBtn float-right">
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                            disabled={assignedUsers.length === 0}
                          >
                            <span
                              onClick={() =>
                                downloadScript(
                                  "AssigningRecordingPolicyToUsersScript.ps1",
                                  getUpdatedAssigningRecordingPolicyToUsersScript()
                                )
                              }
                              className="p-r-2"
                            >
                              {translationsLoading
                                ? "Download"
                                : fetchLabelKeyTranslation(
                                    "BtnDownload",
                                    "Download"
                                  )}
                            </span>
                            <i className="bi bi-download"></i>
                          </Button>
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                            disabled={assignedUsers.length === 0}
                            onClick={() => onClickEmail(getUpdatedAssigningRecordingPolicyToUsersScript(), "AssigningRecordingPolicyToUsers")}
                          >
                            <span className="p-r-2">
                              {translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation("BtnEmail", "Email")}
                            </span>
                            <i className="bi bi-envelope"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="codeData">
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              getUpdatedAssigningRecordingPolicyToUsersScript()
                            );
                          }}
                          className="copCodeDataBtn"
                        >
                          <span>
                            {" "}
                            {translationsLoading
                              ? "Copy Code"
                              : fetchLabelKeyTranslation(
                                  "BtnCopyCode",
                                  "Copy Code"
                                )}
                          </span>
                          <i className="bi bi-files p-l-3"></i>
                        </Button>
                        <div
                          className="fs-13"
                          style={{
                            maxHeight: "200px",
                            overflow: "auto",
                            margin: "0 0 10px 0",
                          }}
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {assigningRecordingPolicyToUsersScript.replace(`"<username@domain.com>"`, assignedUsers.map(u => `"` + u.email + `"`).join(', '))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PanelBarItem>
            <PanelBarItem
              title={`${
                translationsLoading
                  ? "Revoke Recording Policy"
                  : fetchLabelKeyTranslation(
                      "HeadingRevokeRecordingPolicy",
                      "Revoke Recording Policy"
                    )
              }`}
              animation={true}
              icon="folder"
            >
              <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                <div className="visibleDataDiv float-left w-100">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="topCodeBox float-left w-100">
                        <div className="dropBox float-left">
                          <div className="dropBox">
                            <div className="dropBoxH p-r-5">
                              {" "}
                              {translationsLoading
                                ? "Select User"
                                : fetchLabelKeyTranslation(
                                    "FieldSelectUser",
                                    "Select User"
                                  )}
                            </div>
                            <div className="dropBoxInput">
                              <CustomMultiSelect
                                data={masterData?.users?.filter((user: TinyUser) => !user.isArchived)}
                                onChange={(event: MultiSelectChangeEvent) =>
                                  setRemovedUsers([...event.value])
                                }
                                value={removedUsers}
                                dataItemKey="id"
                                itemRender={itemRenderUser}
                                textField="email"
                                filtering={true}
                                placeholder={
                                  translationsLoading
                                    ? "Please select..."
                                    : fetchLabelKeyTranslation(
                                        "PlaceholderMultiSelectOne",
                                        "Please select..."
                                      )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="codeBoxActionBtn float-right">
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                            disabled={removedUsers.length === 0}
                          >
                            <span
                              onClick={() =>
                                downloadScript(
                                  "RemovingRecordingPolicyFromUsersScript.ps1",
                                  getUpdatedRemovingRecordingPolicyFromUsersScript()
                                )
                              }
                              className="p-r-2"
                            >
                              {translationsLoading
                                ? "Download"
                                : fetchLabelKeyTranslation(
                                    "BtnDownload",
                                    "Download"
                                  )}
                            </span>
                            <i className="bi bi-download"></i>
                          </Button>
                          <Button
                            className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                            style={{ height: "26px" }}
                            disabled={removedUsers.length === 0}
                            onClick={() => onClickEmail(getUpdatedRemovingRecordingPolicyFromUsersScript(), "RemovingRecordingPolicyFromUsers")}
                          >
                            <span className="p-r-2">
                              {translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation("BtnEmail", "Email")}
                            </span>
                            <i className="bi bi-envelope"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="codeData">
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              getUpdatedRemovingRecordingPolicyFromUsersScript()
                            );
                          }}
                          className="copCodeDataBtn"
                        >
                          <span>
                            {translationsLoading
                              ? "Copy Code"
                              : fetchLabelKeyTranslation(
                                  "BtnCopyCode",
                                  "Copy Code"
                                )}
                          </span>
                          <i className="bi bi-files p-l-3"></i>
                        </Button>
                        <div
                          className="fs-13"
                          style={{
                            maxHeight: "200px",
                            overflow: "auto",
                            margin: "0 0 10px 0",
                          }}
                        >
                          <p style={{ whiteSpace: "pre-line" }}>
                            {removingRecordingPolicyFromUsersScript.replace(`"<username@domain.com>"`, removedUsers.map(u => `"` + u.email + `"`).join(', '))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PanelBarItem>
          </PanelBar>
        </div>
      </div>
    </div>
  );
};

export default MsTeams;
