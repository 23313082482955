import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import UpsertProfileDialog from "./UpsertProfileDialog";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import CustomUserList from "../../../../components/custom/CustomUserList";
import { TinyUser, User } from "../../../../types/user";
import UpsertProfileUsersDialog from "./UpsertProfileUsersDialog";
import { AccessPermissionEnum } from "../../../../enums/accessPermissionEnum";
import useAuth from "../../../../hooks/useAuth";
import usersService from "../../../../services/users.service";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import "./AuthorizationProfile.scss";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import NotFoundError from "../../../error/NotFoundError";
import CustomSearchFieldTextInput from "../../../../components/custom/form/CustomSearchFieldTextInput";
import UnauthorizedAccess from "../../../error/UnauthorizedAccess";
import useSwal from "../../../../hooks/useSwal";
import { AxiosError } from "axios";
import useMasterData from "../../../../hooks/useMasterData";
import { AccessPermissionListSequenceBuilder } from "../../../../utils/stringUtils";

interface IAuthProfileWithUsersProps {
  profile: AuthorizationProfile;
}

const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
};

const AuthorizationProfiles: React.FC = () => {
  const auth = useAuth();
  const Swal = useSwal();
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [profiles, setProfiles] = useState<AuthorizationProfile[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [profileDialogVisible, setProfileDialogVisible] =
    useState<boolean>(false);
  const [userDialogVisible, setUserDialogVisible] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<
    AuthorizationProfile | undefined
  >(undefined);
  const [selectedProfile, setSelectedProfile] = useState<
    AuthorizationProfile | undefined
  >(undefined);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["PermissionGroup"]
  );
  const [freezeProfile, setFreezeProfile] = useState("");

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "PermissionGroup"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    LoadUsers();
    LoadProfiles();
    LoadFreezeProfile();
  }, []);

  const LoadFreezeProfile = async () => {
    try {
      const data = await authorizationProfileService.getfreezeprofile();
      setFreezeProfile(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("PermissionGroup");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "PermissionGroup"
        ]
      );
      localeCtx?.setPreviousAppLocale("PermissionGroup");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const LoadProfiles = async () => {
    try {
      setLoading(true);
      const data = await authorizationProfileService.getAuthorizationProfiles();
      const profilesList = data.map((profile) => {
        const hasManageAccountPermission = profile.permissions.find(
          (p) => p.id === 10
        );
        if (hasManageAccountPermission) {
          const permissionsList = profile.permissions.filter((ele) => {
            if (ele.id === 10) return false;
            else return true;
          });
          permissionsList.push(hasManageAccountPermission);
          profile.permissions = permissionsList;
        }
        return profile;
      });
      setProfiles(profilesList);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
      setLoading(false);
    }
  };

  const LoadUsers = async () => {
    try {
      setLoading(true);
      const data = await usersService.fetchUsers();
      masterData?.setStandardUsers(data);
      setUsers(data.filter((u) => !u.isArchived));
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message);
      }
    } finally {
    }
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value);
  };

  const searchProfile = (profile: AuthorizationProfile) => {
    const searchVal = searchValue.trim().toLocaleLowerCase();
    if (profile.name?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    if (profile.description?.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const toggleProfileDialog = () => {
    setEditProfile(undefined);
    setProfileDialogVisible(!profileDialogVisible);
  };

  const onProfileUpsert = (
    isUpdated: boolean,
    upsertedProfile: AuthorizationProfile
  ) => {
    if (isUpdated) {
      const updatedProfiles: AuthorizationProfile[] = [];
      profiles?.forEach((pf) => {
        if (pf.id === upsertedProfile.id) {
          updatedProfiles.push(upsertedProfile);
        } else {
          updatedProfiles.push(pf);
        }
      });
      setProfiles(updatedProfiles);
    } else {
      const updatedProfiles = [...profiles, upsertedProfile];
      setProfiles(updatedProfiles);
    }
  };

  const toggleUserDialog = () => {
    setSelectedProfile(undefined);
    setUserDialogVisible(!userDialogVisible);
  };

  const onAddUserHandler = (profileDetails: AuthorizationProfile) => {
    setSelectedProfile(profileDetails);
    setUserDialogVisible(true);
  };

  const onProfileUserUpsert = (
    upsertedProfileUser: User[],
    profile: AuthorizationProfile
  ) => {
    var _users: User[] = [];
    users.forEach((u) => {
      if (upsertedProfileUser.findIndex((up) => up.id === u.id) > -1) {
        _users.push({ ...u, authorizationProfile: profile });
      } else {
        _users.push(u);
      }
    });
    setUsers(_users);
  };

  const onEditHandler = (profileDetails: AuthorizationProfile) => {
    setEditProfile(profileDetails);
    setProfileDialogVisible(true);
  };

  const onDeleteHandler = (profileId: number) => {
    Swal.fire({
      title: `${
        translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation("WarningTitle", "Are you sure?")
      }`,
      text: `${
        translationsLoading
          ? "You won't be able to revert this!"
          : fetchLabelKeyTranslation(
              "WarningText",
              "You won't be able to revert this!"
            )
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Yes, delete it!"
          : fetchLabelKeyTranslation("ConfirmButtonText", "Yes, delete it!")
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelButton", "Cancel")
      }`,
    }).then((result) => {
      if (result.isConfirmed) {
        authorizationProfileService
          .deleteAuthorizationProfile(profileId)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: `${
                translationsLoading
                  ? "Permission Group Deleted!"
                  : fetchLabelKeyTranslation(
                      "PermissionGroupDeletedTitle",
                      "Permission Group Deleted!"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "Permission Group has been deleted."
                  : fetchLabelKeyTranslation(
                      "PermissionGroupDeletedText",
                      "Permission Group has been deleted."
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
            const updatedProfiles = profiles?.filter(
              (pf) => pf.id !== profileId
            );
            setProfiles(updatedProfiles);
          })
          .catch((err) => {
            if (err instanceof AxiosError) {
              Swal.fire({
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("ErrorTitle", "Error")
                }`,
                html: `${
                  translationsLoading
                    ? "Could not delete Permission Group."
                    : fetchLabelKeyTranslation(
                        "DeleteErrorText",
                        "Could not delete Permission Group."
                      ) +
                      "<br/>" +
                      fetchLabelKeyTranslation(
                        "DeleteErrorAdditional",
                        err.response?.data.badRequest
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
            }
          });
      }
    });
  };

  const AuthProfileUsers = (profileProps: IAuthProfileWithUsersProps) => {
    const usersData = users
      ?.filter(
        (u) =>
          u.authorizationProfile?.id === profileProps.profile.id &&
          !u.isArchived
      )
      .map((u) => {
        const userTiny: TinyUser = {
          id: u.id,
          loginUserId: u.loginUserId,
          firstName: u.firstName ?? "",
          lastName: u.lastName ?? "",
          phoneNo: "",
          email: u.email,
          image: u.image ?? "",
          isArchived: u.isArchived,
        };
        return userTiny;
      });

    return (
      <>
        <CustomUserList userList={usersData ? usersData : []} maxDisplay={4} />
        <div className="tblUsr moreUsr users-small mb-2">
          <div className="tblUserRow">
            <div
              className="topUsrAreaPic transparentBtnHover"
              style={{
                opacity: `${
                  !auth?.checkUserAccess(
                    AccessPermissionEnum.ManageSecurityControl
                  ) ||
                  masterData?.telepoDisableConfig?.usermanagement
                    .users.profile.disablePermissionGroup
                    ? "0.4"
                    : ""
                }`,
              }}
            >
              <Button
                className={`topUsrAreaPic-i`}
                onClick={() => onAddUserHandler(profileProps.profile)}
                disabled={
                  !auth?.checkUserAccess(
                    AccessPermissionEnum.ManageSecurityControl
                  ) ||
                  masterData?.telepoDisableConfig?.usermanagement
                    .users.profile.disablePermissionGroup
                }
                style={{ background: "transparent", border: "none" }}
              >
                <div
                  className="contact-list-icon cursor-pointer bg-black-5"
                  title={`${
                    translationsLoading
                      ? "Group Membership"
                      : fetchLabelKeyTranslation(
                          "AddUsersText",
                          "Group Membership"
                        )
                  }`}
                >
                  <div className="contact-list-icon-txt text-black-8">
                    <i className="bi bi-plus fs-25"></i>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ? (
        <UnauthorizedAccess />
      ) : (
        <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
          <div className="contentPushArea boxUserGroups h-100">
            <div className="contentHead h-100">
              <div className="contentHeadTop d-flex justify-content-between align-items-center border-bottom-w-1 border-bottom-solid border-black-2 p-b-15">
                <div className="contentHeadTitle fs-18">
                  {translationsLoading
                    ? "Permission Groups"
                    : fetchLabelKeyTranslation(
                        "PermissionGroupTitle",
                        "Permission Groups"
                      )}
                </div>
                <div className="contentHeadAction">
                  <div className="buttons-container hov-transparent d-flex align-itmes-center p-0">
                    <div className="searchCol searchBox m-r-10">
                      <CustomSearchFieldTextInput
                        className="input-search"
                        placeholder={`${
                          translationsLoading
                            ? "Search…"
                            : fetchLabelKeyTranslation(
                                "SearchBoxPlaceHolder",
                                "Search…"
                              )
                        }`}
                        value={searchValue}
                        updateValue={setSearchValue}
                        searchTextChangeHandler={handleSearchChange}
                        onEscapeKeyFunc={true}
                      />
                    </div>
                    <Button
                      onClick={toggleProfileDialog}
                      className={`btn bg-primary text-white fs-13 fw-normal ${
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageSecurityControl
                        ) ||
                        masterData?.telepoDisableConfig?.usermanagement
                          .permissionGroups.disableCreateUpdateDelete
                          ? "disabledBtn"
                          : ""
                      }`}
                      style={{ height: "29px", margin: "-1px 0 0 0" }}
                      disabled={
                        !auth?.checkUserAccess(
                          AccessPermissionEnum.ManageSecurityControl
                        ) ||
                        masterData?.telepoDisableConfig?.usermanagement
                          .permissionGroups.disableCreateUpdateDelete
                      }
                      title={`${
                        translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation("AddButton", "Add")
                      }`}
                    >
                      <span className="btnSpan">
                        {translationsLoading
                          ? "Add"
                          : fetchLabelKeyTranslation("AddButton", "Add")}
                      </span>
                    </Button>
                    {profileDialogVisible && (
                      <UpsertProfileDialog
                        data={editProfile}
                        onCloseDialog={toggleProfileDialog}
                        onProfileUpsert={onProfileUpsert}
                      />
                    )}
                    {userDialogVisible && selectedProfile !== undefined && (
                      <UpsertProfileUsersDialog
                        profile={selectedProfile}
                        users={users}
                        freezeProfile={freezeProfile}
                        onCloseDialog={toggleUserDialog}
                        onProfileUserUpsert={onProfileUserUpsert}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="contentBody">
                <div className="cardBrand">
                  <div className="cardListArea">
                    <div className="row">
                      {loading ? (
                        <div className="col-md-12 d-flex justify-content-center align-items-center">
                          <Loader type={"infinite-spinner"} />
                        </div>
                      ) : profiles &&
                        profiles.filter((t) => searchProfile(t)).length > 0 ? (
                        profiles
                          .filter((t) => searchProfile(t))
                          .map((profile) => {
                            return (
                              <div className="col col-md-3" key={profile.id}>
                                <div className="cardListBlock">
                                  <div className="cardRows">
                                    <div className="cardColumn">
                                      <div className="colUser">
                                        <div className="tblUsr float-left w-100">
                                          <div className="topUsrAreaPic w-100">
                                            <div className="topUsrAreaPic-tx w-100">
                                              <span
                                                style={{ maxWidth: "95%" }}
                                                className="text-black-13 fs-13 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2 text-truncate"
                                              >
                                                {translationsLoading
                                                  ? profile.name
                                                  : fetchLabelKeyTranslation(
                                                      apLocaleKeyName[
                                                        profile.name
                                                      ] ?? "",
                                                      profile.name
                                                    )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="lblGroupArea float-left w-100">
                                        <div className="userRowLbl p-t-5 p-b-1">
                                          <div className="userColLbl d-flex align-items-center w-100">
                                            <div
                                              className="userLabels float-left fs-12 text-black-9"
                                              style={{ minWidth: "50px" }}
                                            >
                                              {translationsLoading
                                                ? "Users"
                                                : fetchLabelKeyTranslation(
                                                    "UsersTitle",
                                                    "Users"
                                                  )}
                                              :
                                            </div>
                                            <div className="userCircle">
                                              <AuthProfileUsers
                                                profile={profile}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="minTextLabel text-truncate">
                                          <span
                                            className="float-left text-black-9"
                                            style={{ minWidth: "77px" }}
                                          >
                                            {translationsLoading
                                              ? "Description"
                                              : fetchLabelKeyTranslation(
                                                  "DescriptionTitle",
                                                  "Description"
                                                )}
                                            :
                                          </span>
                                          <span className="dotText p-l-3">
                                            <span className="dotTextInner">
                                              {profile.description}
                                            </span>
                                          </span>
                                        </div>
                                        <div className="minTextLabel p-t-7">
                                          <span
                                            className="float-left text-black-9"
                                            style={{ minWidth: "77px" }}
                                          >
                                            {translationsLoading
                                              ? "Permissions"
                                              : fetchLabelKeyTranslation(
                                                  "PermissionsTitle",
                                                  "Permissions"
                                                )}
                                            :
                                          </span>
                                          <span className="p-l-3">
                                            <span className="badgeList">
                                              <div className="keywordRow">
                                                {AccessPermissionListSequenceBuilder(
                                                  profile.permissions
                                                )
                                                  .filter((permission) => {
                                                    if (
                                                      permission.name ===
                                                        "Manage Topics" &&
                                                      !auth.checkTopicAvailibility()
                                                    ) {
                                                      return;
                                                    } else return permission;
                                                  })
                                                  .map((permission) => {
                                                    return (
                                                      <div
                                                        className="keywordTrk"
                                                        key={permission.id}
                                                      >
                                                        {
                                                          masterData?.data?.accessPermissions.find(
                                                            (ap) =>
                                                              ap.id ===
                                                              permission.id
                                                          )?.localizationValue
                                                        }
                                                      </div>
                                                    );
                                                  })}
                                              </div>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="blockIconButton">
                                    {profile.isSystemDefined ? (
                                      <span className="iBtn">
                                        <Button
                                          className={`iconBtn btnBlock-a`}
                                          title={
                                            translationsLoading
                                              ? "Default group"
                                              : fetchLabelKeyTranslation(
                                                  "DefaultGroupProfileText",
                                                  "Default group"
                                                )
                                          }
                                        >
                                          <i className="bi bi-lock-fill fs-17"></i>
                                        </Button>
                                      </span>
                                    ) : (
                                      <span className="iBtn">
                                        <Button
                                          className={`iconBtn btnBlock-a ${
                                            masterData?.telepoDisableConfig
                                              ?.usermanagement.permissionGroups
                                              .disableCreateUpdateDelete
                                              ? "disabledIconBtn"
                                              : ""
                                          }`}
                                          style={{ marginRight: "-5px" }}
                                          onClick={() => onEditHandler(profile)}
                                          disabled={
                                            !auth?.checkUserAccess(
                                              AccessPermissionEnum.ManageSecurityControl
                                            ) ||
                                            masterData?.telepoDisableConfig
                                              ?.usermanagement.permissionGroups
                                              .disableCreateUpdateDelete
                                          }
                                          title={`${
                                            translationsLoading
                                              ? "Edit"
                                              : fetchLabelKeyTranslation(
                                                  "EditBtnText",
                                                  "Edit"
                                                )
                                          }`}
                                        >
                                          <i className="bi bi-pencil fs-17"></i>
                                        </Button>
                                        <span
                                          title={
                                            users?.filter(
                                              (u) =>
                                                u.authorizationProfile?.id ===
                                                  profile.id && !u.isArchived
                                            ).length > 0
                                              ? translationsLoading
                                                ? "Permission Group cannot be deleted as users are assigned."
                                                : fetchLabelKeyTranslation(
                                                    "SystemDefinedButtonErrorText",
                                                    "Permission Group cannot be deleted as users are assigned."
                                                  )
                                              : translationsLoading
                                              ? "Delete"
                                              : fetchLabelKeyTranslation(
                                                  "DeleteBtnText",
                                                  "Delete"
                                                )
                                          }
                                        >
                                          <Button
                                            className={`iconBtn btnBlock-a ${
                                              masterData?.telepoDisableConfig
                                                ?.usermanagement
                                                .permissionGroups
                                                .disableCreateUpdateDelete
                                                ? "disabledIconBtn"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              onDeleteHandler(profile.id)
                                            }
                                            disabled={
                                              !auth?.checkUserAccess(
                                                AccessPermissionEnum.ManageSecurityControl
                                              ) ||
                                              users?.filter(
                                                (u) =>
                                                  u.authorizationProfile?.id ===
                                                    profile.id && !u.isArchived
                                              ).length > 0 ||
                                              masterData?.telepoDisableConfig
                                                ?.usermanagement
                                                .permissionGroups
                                                .disableCreateUpdateDelete
                                            }
                                          >
                                            <i className="bi bi-trash3 fs-17"></i>
                                          </Button>
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <div className="col-md-12 p-l-5">
                          <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                            <span className="fs-13 text-black-9 p-t-20 p-b-20">
                              {translationsLoading
                                ? "No Records Available."
                                : fetchLabelKeyTranslation(
                                    "NoRecordText",
                                    "No Records Available."
                                  )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default AuthorizationProfiles;
