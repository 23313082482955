import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import {
  InsertScorecardQuestion,
  Scorecard,
  ScorecardQuestion,
  ScorecardQuestionGroup,
  UpdateScorecardQuestion,
} from "../../../types/scorecard";
import { axiosWithAuth } from "../../../utils/customAxios";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import scorecardService from "../../../services/scorecard.service";

interface IUpsertQuestionDialogBox {
  toggleDialog: (question: ScorecardQuestion | undefined) => void;
  question: ScorecardQuestion | undefined;
  scorecardId: number;
  scorecardGroupId: number;
  upsertSC: () => void;
  setPage: React.Dispatch<React.SetStateAction<{skip: number; take: number;}>>;
  page: {
    skip: number;
    take: number;
  };
  scorecard: Scorecard;
}

const UpsertQuestionDialogBox: React.FC<IUpsertQuestionDialogBox> = ({
  toggleDialog,
  question,
  scorecardId,
  scorecardGroupId,
  upsertSC,
  page,
  scorecard,
  setPage,
}) => {
  const formRef = useRef<any>();
  const localeCtx = useLocale();
  const swal = useSwal();
  const [loading, setLoading] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertQuestionDialogBox"
    ]
  );

  useEffect(() => {
    fetchTranslations();
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertQuestionDialogBox"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertQuestionDialogBox"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertQuestionDialogBox");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const archiveSweetAlert = async() =>{
    toggleDialog(undefined)    
    const swalOptions: SweetAlertOptions<any, any> = {
      icon: "info",
      title: `${
        translationsLoading
          ? "Scorecard cannot be Updated"
          : fetchLabelKeyTranslation("SwtAltAddFailedTitle","Scorecard cannot be Updated") 
      }`,
      text: `${
        translationsLoading
          ? "You can archive the Scorecard"
          : fetchLabelKeyTranslation("SwtAltArchiveText","You can archive the Scorecard") 
      }`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:  `${
        translationsLoading
          ? "Yes, Archive it!"
          : fetchLabelKeyTranslation("SwtAltArchiveCfnBtnText","Yes, Archive it!") 
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("SwtAltCancelText","Cancel") 
      }`
    };
    const resp = await swal.fire(swalOptions);
    if (resp.isConfirmed) {
      try{
        const updatedScorecard: Scorecard = scorecard;
        updatedScorecard.isArchived = true;
        await axiosWithAuth
        .put(`/scorecard/${scorecard.id}`, updatedScorecard)
        .then((response) => {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: `${
              translationsLoading
                ? "Scorecard Archived"
                : fetchLabelKeyTranslation("SwtAltArchiveSuccessTitle","Scorecard Archived") 
            }`,
            text: `${
              translationsLoading
                ? "Scorecard has been archived Sucessfully"
                : fetchLabelKeyTranslation("SwtAltArchiveSuccessText","Scorecard has been archived Sucessfully") 
            }`,
          };
          swal.fire(swalOptions);
        })
      }catch(err){
        // Archiving Failed
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: `${
            translationsLoading
              ? "Error"
              : fetchLabelKeyTranslation("SwtAltErrorTitle","Error")
          }`,
          text: `${
            translationsLoading
              ? "Could not archive the Scorecard"
              : fetchLabelKeyTranslation("SwtAltArchiveErrorText","Could not archive the Scorecard")
          }`,
        };
        swal.fire(swalOptions);            
      }
    }   
  }
  const addQuestion = async (newQuestion: InsertScorecardQuestion) => {
    setLoading(true)
    const isUsed: boolean = await scorecardService.fetchScorecardUsage(scorecardId);
    if (isUsed) {
      // If user wants to archive
      await archiveSweetAlert();
    }else{
      await axiosWithAuth
      .post(
        `scorecard/${scorecardId}/scorecardquestiongroup/${scorecardGroupId}/scorecardquestion`,
        newQuestion
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard Question added"
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionAddedTitle", 
                  "Scorecard Question added"
                )
          }`,
          text: `${
            translationsLoading
              ? "Your Scorecard question has been added"
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionAddedText", 
                  "Your Scorecard question has been added"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
        const findQuestions = scorecard.questionGroups?.find(
          (questionGroup: ScorecardQuestionGroup) =>
            questionGroup.id === scorecardGroupId
        );
        findQuestions?.questions.push(response.data);
        if (
          findQuestions &&
          findQuestions?.questions.length % page.take !== 0
        ) {
          setPage({
            skip: Math.floor(findQuestions.questions.length / page.take) * page.take,
            take: page.take,
          });
        }
      })
      .catch((err)=>{
          // Adding Failed.
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle","Error")
            }`,
            text: `${
              translationsLoading
                ? "Could not add the Scorecard Question"
                : fetchLabelKeyTranslation("SwtAltAddQstnErrorText","Could not add the Scorecard Question") 
            }`,
          };
          swal.fire(swalOptions);          
      })
      .finally(()=>{
        setLoading(false)
        toggleDialog(undefined);
      })      
    }
    await upsertSC();
  };

  const editQuestion = async (newQuestion: UpdateScorecardQuestion) => {
    setLoading(true)
    const isUsed: boolean = await scorecardService.fetchScorecardUsage(scorecardId);
    if (isUsed) {
      // If user wants to archive
      await archiveSweetAlert();          
    }
    else{
      await axiosWithAuth
      .put(
        `scorecard/${scorecardId}/scorecardquestiongroup/${scorecardGroupId}/scorecardquestion/${newQuestion.id}`,
        newQuestion
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard Question updated"
              : fetchLabelKeyTranslation(
                  "SwtAltSCQuesUpdatedSuccessTitle",
                  "Scorecard Question updated"
                )
          }`,
          text: `${
            translationsLoading
              ? "Your Scorecard question has been updated "
              : fetchLabelKeyTranslation(
                  "SwtAltSCQuesUpdatedSuccessText",
                  "Your Scorecard question has been updated"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK") 
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err)=>{
          // Updation Failed.
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              translationsLoading
                ? "Could not update the Scorecard Question"
                : fetchLabelKeyTranslation("SwtAltSCUpdateErrorText", "Could not update the Scorecard Question")
            }`,
          };
          swal.fire(swalOptions);          
      })
      .finally(()=>{
        setLoading(false)
        toggleDialog(undefined);
      })
    }
    await upsertSC();
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Question Text cannot be empty"
          : fetchLabelKeyTranslation(
              "SCQuestionNameValidationEmptyMsg",
              "Question Text cannot be empty" 
            )
      }`;
    }
    if (value.length > 500) {
      return `${
        translationsLoading
          ? "Question Text length cannot be more than 500!"
          : fetchLabelKeyTranslation(
              "SCQuestionNameValidationLengthMsg", 
              "Question Text length cannot be more than 500!"
            )
      }`;
    }

    return "";
  };

  const handleUpsert = async () => {
    const form = formRef.current as Form;
    const createNewQuestion: InsertScorecardQuestion = {
      questionText: form.values.questionText,
    };
    const updateNewQuestion: UpdateScorecardQuestion = {
      id: question?.id ?? 0,
      questionText: form.values.questionText,
    };
    question
      ? await editQuestion(updateNewQuestion)
      : await addQuestion(createNewQuestion);
  };
  return (
    <Form
      ref={formRef}
      initialValues={{
        questionText: question?.questionText ? question?.questionText : "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              question
                ? `${
                    translationsLoading
                      ? "Edit Question"
                      : fetchLabelKeyTranslation(
                          "TitleEditQuestion",
                          "Edit Question"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Question"
                      : fetchLabelKeyTranslation(
                          "TitleAddQuestion",
                          "Add Question"
                        )
                  }`
            }
            onClose={() => toggleDialog(undefined)}
            closeIcon={!loading}
          >
            
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="questionText"
                      name="questionText"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("questionText")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter question ..."
                          : fetchLabelKeyTranslation(
                              "FieldQuesTextPlaceholder",
                              "Enter question ..." 
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button
                className={`btn bg-black-5`}
                onClick={() => toggleDialog(undefined)}
                disabled={loading}
                >
                {`${
                  translationsLoading
                  ? "Cancel"
                  : fetchLabelKeyTranslation("SwtAltCancelText", "Cancel") 
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                disabled={!formRenderProps.valid || loading}
                onClick={handleUpsert}
              >
                {`${
                      translationsLoading
                      ? "Save"
                      : fetchLabelKeyTranslation("SaveBtnText", "Save")
                    }`}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertQuestionDialogBox;
