import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Input,
  Checkbox,
  CheckboxChangeEvent,
  InputChangeEvent,
} from "@progress/kendo-react-inputs";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { TinyUser } from "../types/user";
import CustomUserGridInfo from "./custom/grid/CustomUserGridInfo";
import { SweetAlertOptions } from "sweetalert2";
import { Loader } from "@progress/kendo-react-indicators";
import { Notification } from "../types/notification/Notification";
import { AxiosError } from "axios";
import { Dictionary } from "../types/Dictionary";
import useLocale from "../hooks/useLocale";
import CustomInput from "./custom/form/CustomInput";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { SharedResourceDetail } from "../types/shared-resource";
import { SharedResourceTypes } from "../types/shared-resource/SharedResourceTypes";
import { Button } from "@progress/kendo-react-buttons";
import { formatUsernameToPascalCase } from "../utils/profileUtils";
import moment from "moment";
import 'moment/min/locales';
import useAuth from "../hooks/useAuth";
import usersService from "../services/users.service";
import sharedResourceService from "../services/sharedResource.service";
import notificationService from "../services/notification.service";
import { EmailValidator } from "../utils/validator";
import useSwal from "../hooks/useSwal";
import useMasterData from "../hooks/useMasterData";

interface DialogShareProps {
  onClose: () => void;
  resourceObjId: number;
  recordingPath: string;
}

interface TinyUserSelect extends TinyUser {
  checked?: boolean;
}

const durationOptions = [
  { text: "1 day", value: 1 },
  { text: "2 days", value: 2 },
  { text: "7 days", value: 7 },
  { text: "30 days", value: 30 },
  { text: "90 days", value: 90 },
];

const ShareDialog: React.FC<DialogShareProps> = ({
  onClose,
  resourceObjId,
  recordingPath,
}) => {
  const auth = useAuth();
  const swal = useSwal();
  const master = useMasterData();
  const localeCtx = useLocale();
  //All States Declared
  const [selected, setSelected] = React.useState<number>(0);
  const [internalUsers, setInternalUsers] = useState<TinyUserSelect[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [externalUsers, setExternalUsers] = useState<string[]>([]);
  const [translationsLoading, setTranslationsLoading] =
  useState<boolean>(false);
const [translations, setTranslations] = useState<
  Dictionary<string> | undefined
>(localeCtx?.selectedLocale?.current.componentTranslations["ShareDialog"]);
  const [duration, setDuration] = useState<{
    text: string;
    value: number;
  }>(durationOptions[0]);
  const [usersLoading, setUsersLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [externalUserError, setExternalUserError] = useState<
    string | undefined
  >();
  const [usersSelected, setUsersSelected] = useState<boolean>(true);
  const [sharing, setSharing] = useState<boolean>(false);

  const externalUserFormRef = useRef<any>();

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const fetchInternalUsers = async () => {
    try {
      var users = await usersService.fetchUsers();
      master?.setStandardUsers(users);
      users = users.filter(obj => {return obj.loginEnabled === true && obj.isArchived === false});
      var tinyUsers = users.map((user) => {
        let obj: TinyUserSelect = {
          id: user.id,
          loginUserId:user.loginUserId,
          firstName: user.firstName ?? "",
          lastName: user.lastName ?? "",
          email: user.email,
          phoneNo: user.phoneNo,
          image: user.image,
          isArchived: user.isArchived,
          checked: false,
        };
        return obj;
      });
      setInternalUsers(tinyUsers);
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(err.message);
      }
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    setUsersSelected(false);
    fetchInternalUsers();
  }, []);

  const checkedInternalUser = (
    e: CheckboxChangeEvent,
    user: TinyUserSelect
  ) => {
    const updatedInternalUsers = internalUsers.map((u) => {
      if (u.id === user.id) {
        u.checked = e.value;
      }
      return u;
    });
    // .sort(TinyUserSelectComparer);

    if (
      updatedInternalUsers.filter((u) => u.checked).length === 0 &&
      externalUsers.length === 0
    ) {
      setUsersSelected(false);
    } else {
      setUsersSelected(true);
    }

    setInternalUsers(updatedInternalUsers);
  };

  const updateSearchText = (e: InputChangeEvent) => {
    setSearchTerm(e.value);
  };

  const handleDurationChange = (event: ComboBoxChangeEvent) => {
    setDuration(event.value);
  };

  const searchInternalUser = (user: TinyUserSelect) => {
    const searchVal = searchTerm.trim().toLocaleLowerCase();
    const name = user.firstName + " " + user.lastName;
    if (name.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    if (user.email.toLocaleLowerCase().includes(searchVal)) {
      return true;
    }
    return false;
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("ShareDialog");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "ShareDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("ShareDialog");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const addExternalUser = (dataItem: { [name: string]: any }) => {
    setExternalUserError(undefined);
    const enteredVal = dataItem.email as string;
    if (!EmailValidator(enteredVal)) {
      setExternalUserError("Please provide a valid email!");
      return;
    }

    let exists = false;
    externalUsers.forEach((e) => {
      if (e.trim() === enteredVal.trim()) {
        setExternalUserError("Email already added!");
        exists = true;
      }
    });

    if (exists) {
      return;
    }

    const updatedExternalUsers = [...externalUsers, enteredVal];
    setUsersSelected(true);
    setExternalUsers((prevState) => [...prevState, enteredVal]);
    const form = externalUserFormRef.current as Form;
    form.resetForm();
  };

  const deleteExternalUser = (u: string) => {
    const updatedExternalUsers = externalUsers.filter((e) => e !== u);
    if (
      updatedExternalUsers.length === 0 &&
      internalUsers.filter((u) => u.checked === true).length === 0
    ) {
      setUsersSelected(false);
    }
    setExternalUsers(updatedExternalUsers);
    const form = externalUserFormRef.current as Form;
    form.resetForm();
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "ShareDialog"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const handleSubmit = async () => {
    const hostname = window.location.hostname;
    const appUrl =
      hostname === "localhost"
        ? `http://${hostname}:3000`
        : `https://${hostname}`;
    const recordingUrl = `${appUrl}/shared${recordingPath}`;
    const selectedInternalUsers: TinyUser[] = internalUsers
      .filter((u) => u.checked)
      .map((user) => {
        const obj: TinyUser = {
          id: user.id,
          loginUserId: user.loginUserId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNo: user.phoneNo,
          image: user.image,
          isArchived: user.isArchived
        };
        return obj;
      });
    const resources: SharedResourceDetail[] = [];
    resources.push({
      id: 0,
      resourceObjId: resourceObjId,
      resourceType: SharedResourceTypes.CallDetail,
    });
    
    try {
      setSharing(true);
      const response = await sharedResourceService.share(
        appUrl,
        recordingUrl,
        duration.value,
        selectedInternalUsers,
        externalUsers,
        resources
      );
      const swalOptions : SweetAlertOptions<any, any> = {
        icon: "success",
        title: `${
          translationsLoading
            ? "Recording Shared"
            : fetchLabelKeyTranslation("RecordingShared", "Recording Shared")
        }`,
        text: `${
          translationsLoading
            ? "Your recording has been shared"
            : fetchLabelKeyTranslation("YourRecordingShared", "Your recording has been shared")
        }`,
        confirmButtonText: `${
          translationsLoading
            ? "OK"
            : fetchLabelKeyTranslation("SharedOK", "OK")
        }`
      }
      await swal.fire(swalOptions);
      const userNotifications: Notification[] = [];
      response.sharedUsers
        .filter((u) => u.isExternalUser === false)
        .forEach((u) => {
          const user = selectedInternalUsers.find(
            (i) => i.id === u.sharedUserId
          );
          if (auth?.user && user) {
            userNotifications.push({
              id: 0,
              notificationType: {
                id: 1,
                type: "Shared",
                localizationKey: "Shared",
              },
              user: user,
              notificationLink: `${appUrl}/share?token=${u.shareAuthToken}`,
              notifyAt: new Date(moment.utc().format()).toISOString()
              .replace("T", " ")
              .replace("Z", "")
              .replace(/\.\d+/, ""),
              seen: false,
            });
          }
        });
      notificationService.sendNotifications(userNotifications);
      onClose();
    } catch (err) {
      if (err instanceof AxiosError) {
        const swalOptions : SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Could not share recording.",
        }
        await swal.fire(swalOptions);
      }
    } finally {
      setSharing(false);
    }
  };

  return (
    <div>
      <Dialog
        className="dialogShareList"
        title={`${
          translationsLoading
            ? "Share"
            : fetchLabelKeyTranslation("ShareTitle", "Share")
        }`}
        onClose={onClose}
        style={{ zIndex: 99 }}
      >
        <div
          className="shareList tab-line tabContentWidth-100 tabContentP-0"
          style={{ minWidth: "250px", maxWidth: "360px" }}
        >
          <TabStrip
            className="tab-textTransformNone"
            selected={selected}
            onSelect={handleSelect}
          >
            <TabStripTab title={`${
             translationsLoading
            ? "Internal"
            : fetchLabelKeyTranslation("InternalHeading", "Internal")
              }`}>
              <div
                style={{
                  padding: "20px 0 10px 0",
                }}
              >
                <div className="detailColBox p-r-10">
                  <div className="UserLiSearch p-b-5">
                    <div className="formInput">
                      <Input
                        style={{ height: "32px" }}
                        onChange={updateSearchText}
                        disabled={usersLoading}
                        placeholder="Search Users..."
                        value={searchTerm}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      maxHeight: "300px",
                      minHeight: "300px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {usersLoading && (
                      <Loader
                        type={"infinite-spinner"}
                        themeColor={"primary"}
                        style={{ display: "flex", justifyContent: "center" }}
                      />
                    )}
                    {!usersLoading && error && <span>{error}</span>}
                    {!usersLoading && !error && (
                      <table style={{ width: "100%" }}>
                        <tbody>
                          {internalUsers
                            .filter((u) => searchInternalUser(u))
                            .map((user) => {
                              return (
                                <tr key={user.id}>
                                  <td>
                                    <CustomUserGridInfo userInfo={user} />
                                  </td>
                                  <td>
                                    <div className="selectUserLi p-r-15">
                                      <Checkbox
                                        defaultChecked={false}
                                        value={user.checked}
                                        onChange={(e) => {
                                          checkedInternalUser(e, user);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </TabStripTab>
            <TabStripTab title={`${
             translationsLoading
            ? "External"
            : fetchLabelKeyTranslation("ExternalHeading", "External")
              }`}>
              <div>
                <div className="detailColBox p-r-10">
                  <div className="UserLiSearch p-b-5">
                    <Form
                      ref={externalUserFormRef}
                      initialValues={{
                        email: "",
                      }}
                      onSubmit={addExternalUser}
                      render={(formRenderProps: FormRenderProps) => (
                        <FormElement>
                          <fieldset
                            className={"k-form-fieldset"}
                            style={{ padding: "10px 0 0 0" }}
                          >
                            <span>Send link</span>
                            <div className="d-flex align-items-center justify-content-between">
                              <Field
                                name={"email"}
                                type={"email"}
                                placeholder={"To: email"}
                                value={formRenderProps.valueGetter("email")}
                                component={CustomInput}
                                style={{ height: "32px", width: "315px" }}
                              />
                              <button
                                type="submit"
                                className="btn btn-link cursor-pointer"
                                disabled={
                                  !formRenderProps.allowSubmit &&
                                  formRenderProps.valid
                                }
                              >
                                <span
                                  className="fs-20 text-primary radius-50 d-flex align-items-center justify-content-center"
                                  style={{ height: "30px", width: "20px" }}
                                  title="Add"
                                >
                                  <i className="bi bi-send-plus-fill"></i>
                                </span>
                              </button>
                            </div>
                          </fieldset>
                          <Error>{externalUserError}</Error>
                        </FormElement>
                      )}
                    />
                  </div>
                  <div
                    style={{
                      maxHeight: "300px",
                      minHeight: "266px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {externalUsers.map((u, idx) => {
                          return (
                            <tr key={u}>
                              <td>
                                <CustomUserGridInfo
                                  userInfo={{
                                    id: idx,
                                    loginUserId: 0,
                                    email: u,
                                    firstName: "",
                                    lastName: "",
                                    isArchived: false
                                  }}
                                  iconCls="bi bi-envelope"
                                />
                              </td>
                              <td>
                                <div className="selectUserLi p-r-15">
                                  <a
                                    className="cursor-pointer"
                                    onClick={() => deleteExternalUser(u)}
                                  >
                                    <span
                                      className="fs-16 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                      style={{ height: "30px", width: "30px" }}
                                      title="Delete"
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </TabStripTab>
          </TabStrip>
          <div className="selectDurationLabel float-left w-100 p-t-10">
            <div className="formBoxRow p-t-5 p-b-5 d-flex justify-content-between align-items-center">
              <div className="formBoxLabel fs-14" style={{ width: "150px" }}>
              {`${
                translationsLoading
                  ? "Duration"
                  : fetchLabelKeyTranslation("DurationLbl", "Duration")
                }`+":"}
              </div>
              <div className="formBoxAction">
                <div className="formInput">
                  <ComboBox
                    style={{ height: "32px" }}
                    data={durationOptions}
                    onChange={handleDurationChange}
                    textField="text"
                    value={duration}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={onClose}
          >
            {`${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelBtn", "Cancel")
          }`}
          </button>
          <Button
            themeColor={"primary"}
            fillMode={"solid"}
            disabled={!usersSelected}
            onClick={handleSubmit}
          >
            {sharing ? (
              <Loader size="small" type="infinite-spinner" themeColor="light" />
            ) : (
              `${
                translationsLoading
                  ? "Share"
                  : fetchLabelKeyTranslation("ShareBtn", "Share")
                }`
            )}
          </Button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
};

export default ShareDialog;
