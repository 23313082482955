import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { Input } from "@progress/kendo-react-inputs";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import recordingsService from "../../services/recordings.service";
import { Comment, UpdateRecordingComment } from "../../types/recording";
import { formatDateTime, formatSecondsToTime } from "../../utils/dateTimeUtils";
import {
  formatUsernameToPascalCase,
  getRandomProfileColor,
  getUserInitials,
} from "../../utils/profileUtils";
import CustomInput from "../custom/form/CustomInput";
import useMasterData from "../../hooks/useMasterData";
import CustomFormMention from "../custom/form/CustomFormMention";
import {
  fetchUsersFromCommentText,
  filterCommentText,
} from "./RecordingCommentsHelperFunctions";
import notificationService from "../../services/notification.service";
import moment from "moment";
import 'moment/min/locales';
import { Notification } from "../../types/notification/Notification";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import { AccessPermissionEnum } from "../../enums";
import { TinyUser } from "../../types/user";
import { AxiosError } from "axios";

const commentValidator = (value: string) => {
  if (!value) {
    return "Please enter a comment.";
  }
  if (value.length > 1000) {
    return "Comment length cannot be more than 1000!";
  }

  return "";
};

interface TinyUserSelect extends TinyUser {
  checked?: boolean;
}

interface RecordingCommentItemProps {
  recordingId: number;
  comment: Comment;
  audioLoaded: boolean;
  disableCommentPlay: boolean;
  stopCommentPlay: boolean;
  playedCommentId: number;
  shared?: boolean;
  onDeleteComment: (id: number) => void;
  onUpdateComment: (updatedComment: Comment) => void;
  onCommentMouseOver: (commentId: number) => void;
  onCommentMouseOut: () => void;
  onUserCommentPlay: (comment: Comment) => void;
  onUserCommentStop: () => void;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  translationsLoading: boolean;
  platformCallDetailId?: string;
  internalUsers: TinyUserSelect[];
  recordingPath: string;
  setTotalCommentsTemp: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  totalCommentsTemp?: number;
}

const RecordingCommentItem: React.FC<RecordingCommentItemProps> = ({
  recordingId,
  comment,
  audioLoaded,
  disableCommentPlay,
  stopCommentPlay,
  playedCommentId,
  shared,
  onDeleteComment,
  onUpdateComment,
  onCommentMouseOver,
  onCommentMouseOut,
  onUserCommentPlay,
  onUserCommentStop,
  fetchLabelKeyTranslation,
  translationsLoading,
  platformCallDetailId,
  internalUsers,
  recordingPath,
  setTotalCommentsTemp,
  totalCommentsTemp,
}) => {
  const auth = useAuth();
  const master = useMasterData();
  const swal = useSwal();
  const [userCommentEditingId, setUserCommentEditingId] = useState<number>(-1);
  const [commentUpdateLoading, setCommentUpdateLoading] =
    useState<boolean>(false);
  const [commentDeleteLoading, setCommentDeleteLoading] =
    useState<boolean>(false);
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  const onUserCommentEditHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setUserCommentEditingId(id);
  };

  const compareState = (textValue: any) => {
    if (textValue.target.value == comment.commentText) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  const onUserCommentDeleteHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    const swalOptions: SweetAlertOptions<any, any> = {
      title: `${
        translationsLoading
          ? "Are you sure?"
          : fetchLabelKeyTranslation(
              "SwtAltDeleteSuccessTitle",
              "Are you sure?"
            )
      }`,
      text: `${
        translationsLoading
          ? "You won't be able to revert this!"
          : fetchLabelKeyTranslation(
              "SwtAltDeleteSuccessText",
              "You won't be able to revert this!"
            )
      }`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Yes, delete it!"
          : fetchLabelKeyTranslation(
              "SwtAltDeleteConfirmBtnText",
              "Yes, delete it!"
            )
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel"
          : fetchLabelKeyTranslation("CancelText", "Cancel")
      }`,
    };
    swal.fire(swalOptions).then((result) => {
      if (result.isConfirmed) {
        setCommentDeleteLoading(true);
        recordingsService
          .deleteRecordingComment(recordingId, id, shared === true)
          .then(() => {
            const userNotifications: Notification[] = [];
            if (!comment.isPrivate) {
              const users = fetchUsersFromCommentText(
                comment.commentText,
                master?.users
              );
              users.forEach((user) => {
                userNotifications.push({
                  id: 0,
                  notificationType: {
                    id: 2,
                    type: "Tagged",
                    localizationKey: "Tagged",
                  },
                  user: user,
                  notificationLink: `#`,
                  notifyAt: new Date(moment.utc().format())
                    .toISOString()
                    .replace("T", " ")
                    .replace("Z", "")
                    .replace(/\.\d+/, ""),
                  seen: false,
                });
              });
              totalCommentsTemp && setTotalCommentsTemp(totalCommentsTemp - 1);
            }

            try {
              if (!comment.isPrivate)
                notificationService.sendNotifications(userNotifications);
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "success",
                title: `${
                  translationsLoading
                    ? "Comment Deleted!"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedTitle",
                        "Comment Deleted!"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Your comment has been deleted."
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedText",
                        "Your comment has been deleted."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              };
              swal.fire(swalOptions);
              onDeleteComment(id);
            } catch (error) {
              if (error instanceof Error) {
                const swalOptions: SweetAlertOptions<any, any> = {
                  icon: "error",
                  title: `${
                    translationsLoading
                      ? "Oops..."
                      : fetchLabelKeyTranslation(
                          "SwtAltAddedFailedTitle",
                          "Oops..."
                        )
                  }`,
                  text: `${
                    translationsLoading
                      ? "Something went wrong! Please Try again..."
                      : fetchLabelKeyTranslation(
                          "SwtAltAddedFailedText",
                          "Something went wrong! Please Try again...Oops..."
                        )
                  }`,
                };
                swal.fire(swalOptions);
              }
            }
          })
          .catch((err) => {
            if (err instanceof Error) {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedTitle",
                        "Error"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete comment."
                    : fetchLabelKeyTranslation(
                        "SwtAltDeletedFailedText",
                        "Could not delete comment."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              };
              swal.fire(swalOptions);
            }
          })
          .finally(() => {
            setCommentDeleteLoading(false);
          });
      }
    });
  };

  const onUserCommentTextEditCancelHandler = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setUserCommentEditingId(-1);
  };

  const editCommentSubmitHandler = (dataItem: { [name: string]: any }) => {
    const hostname = window.location.hostname;
    const appUrl =
      hostname === "localhost"
        ? `http://${hostname}:3000`
        : `https://${hostname}`;
    const recordingUrl = `${appUrl}/shared${recordingPath}`;
    const users = fetchUsersFromCommentText(
      dataItem.commentText,
      master?.users
    );
    const taggedUsers = fetchUsersFromCommentText(
      comment.commentText,
      master?.users
    );
    const filteredNewTaggedUsers: TinyUser[] = users.filter(
      (user: TinyUser) => {
        if (!taggedUsers.find((tUser: TinyUser) => tUser.id === user.id))
          return user;
      }
    );
    const updateCommentData: UpdateRecordingComment = {
      id: dataItem.commentId,
      commentText: dataItem.isCommentPrivate
        ? filterCommentText(dataItem.commentText)
        : dataItem.commentText,
      isPrivate: dataItem.isCommentPrivate,
      users: filteredNewTaggedUsers,
      recordingUrl,
    };
    setCommentUpdateLoading(true);
    recordingsService
      .updateRecordingComment(
        recordingId,
        dataItem.commentId,
        updateCommentData,
        shared === true
      )
      .then((res) => {
        const userNotifications: Notification[] = [];
        if (!dataItem.isCommentPrivate) {
          users.forEach((user) => {
            userNotifications.push({
              id: 0,
              notificationType: {
                id: 2,
                type: "Tagged",
                localizationKey: "Tagged",
              },
              user: user,
              notificationLink: `${appUrl}/callDetail/${platformCallDetailId}?recordingId=${recordingId}`,
              notifyAt: new Date(moment.utc().format())
                .toISOString()
                .replace("T", " ")
                .replace("Z", "")
                .replace(/\.\d+/, ""),
              seen: false,
            });
          });
        }
        try {
          if (!dataItem.isCommentPrivate && userNotifications.length > 0)
            notificationService.sendNotifications(userNotifications);
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "success",
            title: `${
              translationsLoading
                ? "Comment Updated"
                : fetchLabelKeyTranslation(
                    "SwtAltUpdateSuccessTitle",
                    "Comment Updated"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
          onUpdateComment(res);
          setUserCommentEditingId(-1);
        } catch (error) {
          if (error instanceof Error) {
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "error",
              title: `${
                translationsLoading
                  ? "Oops..."
                  : fetchLabelKeyTranslation(
                      "SwtAltAddedFailedTitle",
                      "Oops..."
                    )
              }`,
              text: `${
                translationsLoading
                  ? "Something went wrong! Please Try again..."
                  : fetchLabelKeyTranslation(
                      "SwtAltAddedFailedText",
                      "Something went wrong! Please Try again...Oops..."
                    )
              }`,
            };
            swal.fire(swalOptions);
          }
        }
      })
      .catch((err) => {
        if (err instanceof Error) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops..."
                : fetchLabelKeyTranslation("SwtAltAddedFailedTitle", "Oops...")
            }`,
            text: `${
              translationsLoading
                ? "Something went wrong! Please Try again..."
                : fetchLabelKeyTranslation(
                    "SwtAltAddedFailedText",
                    "Something went wrong! Please Try again..."
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        }
      })
      .finally(() => {
        setCommentUpdateLoading(false);
      });
  };

  const onUserCommentPlayHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    onUserCommentPlay(comment);
  };

  const onUserCommentStopHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    onUserCommentStop();
  };

  const onUserCommentMouseOverHandler = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (audioLoaded) {
      onCommentMouseOver(comment.id);
    }
  };

  const onUserCommentMouseOutHandler = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (audioLoaded) {
      onCommentMouseOut();
    }
  };
  return (
    <div className="conversation-list p-r-15">
      {/* User profile pic */}
      <div className="tblUsr mt-2">
        <div className="topUsrAreaPic">
          <div className="topUsrAreaPic-i">
            <div
              className={`contact-list-icon ${getRandomProfileColor(
                comment.commentedBy ? comment.commentedBy.id : 0
              )}`}
            >
              <div className="contact-list-icon-txt">
                {getUserInitials(
                  comment.commentedBy?.firstName
                    ? comment.commentedBy?.firstName
                    : "",
                  comment.commentedBy?.lastName
                    ? comment.commentedBy?.lastName
                    : ""
                )
                  ? getUserInitials(
                      comment.commentedBy?.firstName
                        ? comment.commentedBy?.firstName
                        : "",
                      comment.commentedBy?.lastName
                        ? comment.commentedBy?.lastName
                        : ""
                    )
                  : comment.commentedBy?.email[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user-chat-content ml-1">
        <div className="ctext-wrap m-b-15 float-left w-100">
          <div
            className="ctext-wrap-content float-left w-100"
            onMouseOver={onUserCommentMouseOverHandler}
            onMouseLeave={onUserCommentMouseOutHandler}
          >
            {/* Comment action buttons */}
            <div className="chat-usr-icon position-absolute top-5 right-5">
              <span
                className="m-l-0"
                title={`${
                  playedCommentId === comment.id
                    ? translationsLoading
                      ? "Stop Playing"
                      : fetchLabelKeyTranslation(
                          "TitleStopPlaying",
                          "Stop Playing"
                        )
                    : translationsLoading
                    ? "Play Comment"
                    : fetchLabelKeyTranslation(
                        "TitlePlayComment",
                        "Play Comment"
                      )
                } `}
              >
                {playedCommentId !== comment.id ? (
                  <button
                    className={`btn p-0 m-0 fs-15 ${
                      disableCommentPlay ? "" : "cmntPlayStopBtn"
                    }`}
                    disabled={disableCommentPlay}
                    onClick={onUserCommentPlayHandler}
                  >
                    <i className="bi bi-play-circle-fill text-black-12"></i>
                  </button>
                ) : (
                  <button
                    className="btn p-0 m-0 fs-15 cmntPlayStopBtn"
                    onClick={onUserCommentStopHandler}
                  >
                    <i className="bi bi-stop-circle-fill text-black-12"></i>
                  </button>
                )}
              </span>
              {(!shared || (shared && auth?.accessToken)) &&
                comment.commentedBy?.id === auth?.user?.id && (
                  <>
                    {userCommentEditingId !== comment.id &&
                      !commentUpdateLoading && (
                        <span
                          className="m-l-10"
                          title={`${
                            translationsLoading
                              ? "Edit"
                              : fetchLabelKeyTranslation("TitleEdit", "Edit")
                          }`}
                        >
                          <Button
                            className="btn btn-link p-0 m-0 fs-15"
                            onClick={(e) =>
                              onUserCommentEditHandler(e, comment.id)
                            }
                          >
                            <i className="bi bi-pencil text-black-11"></i>
                          </Button>
                        </span>
                      )}
                    {(commentUpdateLoading || commentDeleteLoading) && (
                      <span
                        className="m-l-10"
                        title={`${
                          translationsLoading
                            ? "Loading"
                            : fetchLabelKeyTranslation(
                                "TitleLoading",
                                "Loading"
                              )
                        }`}
                      >
                        <Loader
                          size={"small"}
                          type={"infinite-spinner"}
                          themeColor={"dark"}
                        />
                      </span>
                    )}
                    {!commentDeleteLoading && (
                      <span
                        className="m-l-10"
                        title={`${
                          translationsLoading
                            ? "Delete"
                            : fetchLabelKeyTranslation("TitleDelete", "Delete")
                        }`}
                      >
                        <Button
                          className="btn btn-link p-0 m-0 fs-15"
                          onClick={(e) =>
                            onUserCommentDeleteHandler(e, comment.id)
                          }
                        >
                          <i className="bi bi-trash text-black-11"></i>
                        </Button>
                      </span>
                    )}
                  </>
                )}
            </div>
            {/* Comment details */}
            <div className="chat-usr mb-1">
              <span
                className="fs-16 text-black-10 m-r-10"
                title={comment.isPrivate ? "Private" : "Public"}
              >
                {
                  <i
                    className={`bi ${
                      comment.isPrivate ? "bi-lock-fill" : "bi-people"
                    }`}
                  ></i>
                }
              </span>
              <span className="fs-13 font-weight-bold mr-2">
                {formatUsernameToPascalCase(
                  comment.commentedBy?.firstName
                    ? comment.commentedBy.firstName
                    : "",
                  comment.commentedBy?.lastName
                    ? comment.commentedBy.lastName
                    : ""
                )
                  ? formatUsernameToPascalCase(
                      comment.commentedBy?.firstName
                        ? comment.commentedBy.firstName
                        : "",
                      comment.commentedBy?.lastName
                        ? comment.commentedBy.lastName
                        : ""
                    )
                  : comment.commentedBy?.email}
              </span>
              <span className="fs-11 text-muted">
                {comment.commentedAt
                  ? formatDateTime(comment.commentedAt, "MMM DD, YYYY, HH:mm")
                  : ""}
              </span>
              {comment.segmentStart && (
                <>
                  <span className="fs-11 text-muted p-r-10 p-l-10">|</span>
                  <span className="fs-11 text-muted">
                    {formatSecondsToTime(Math.floor(comment.segmentStart))} /{" "}
                    {formatSecondsToTime(
                      Math.floor(comment.segmentEnd as number)
                    )}
                  </span>
                </>
              )}
            </div>
            {/* User comment */}
            {userCommentEditingId !== comment.id ? (
              <>
                {comment.commentText.split(" ").map((cmt) => {
                  if (
                    cmt.length > 4 &&
                    cmt[0] === "@" &&
                    cmt[1] === "[" &&
                    cmt[cmt.length - 2] === "]" &&
                    cmt[cmt.length - 1] === "*"
                  ) {
                    let newComment = cmt.slice(2, cmt.length - 2);
                    if (
                      master?.users.find((user) => user.email === newComment)
                    ) {
                      return (
                        <>
                          <span
                            className="mb-0 fs-13 editChat"
                            style={{ backgroundColor: "#cee4e5" }}
                          >
                            {newComment}
                          </span>
                          <span> </span>
                        </>
                      );
                    } else {
                      return (
                        <span className="mb-0 fs-13 editChat">{cmt} </span>
                      );
                    }
                  } else {
                    return <span className="mb-0 fs-13 editChat">{cmt} </span>;
                  }
                })}
              </>
            ) : (
              // Edit comment form
              <Form
                onSubmit={editCommentSubmitHandler}
                initialValues={{
                  commentText: comment.commentText,
                  commentId: comment.id,
                  isCommentPrivate: comment.isPrivate,
                }}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement>
                    <fieldset className={"k-form-fieldset"}>
                      <div>
                        <Field
                          name={"commentId"}
                          type={"hidden"}
                          component={Input}
                        />
                        <Field
                          className="editableInput"
                          name={"commentText"}
                          component={CustomFormMention}
                          validator={commentValidator}
                          data={internalUsers}
                          isMention={
                            !comment.isPrivate &&
                            auth?.checkUserAccess(
                              AccessPermissionEnum.ShareCalls
                            )
                          }
                          singleLineInput={true}
                          onChange={(e) => {
                            compareState(e);
                          }}
                        />
                      </div>
                    </fieldset>
                    <span className="float-left w-100 text-right">
                      <button
                        type={"submit"}
                        className="btn btn-sm fs-17 font-weight-semi m-r-4 p-0"
                        disabled={
                          !formRenderProps.allowSubmit ||
                          commentUpdateLoading ||
                          buttonStateTracker
                        }
                      >
                        <i className="bi bi-check-square text-success"></i>
                      </button>
                      <button
                        type={"button"}
                        className="btn btn-sm fs-17 font-weight-semi p-0"
                        disabled={commentUpdateLoading}
                        onClick={(e) =>
                          onUserCommentTextEditCancelHandler(e, comment.id)
                        }
                      >
                        <i className="bi bi-x-square text-danger"></i>
                      </button>
                    </span>
                  </FormElement>
                )}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordingCommentItem;
