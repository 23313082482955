import React, { useContext, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Link, useParams } from "react-router-dom";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  Scorecard,
  ScorecardQuestion,
  ScorecardQuestionGroup,
} from "../../../types/scorecard";
import UpsertQuestionDialogBox from "./UpsertQuestionDialogBox";
import UpsertQuestionGroupDialog from "./UpsertQuestionGroupDialog";
import { AccessPermissionEnum } from "../../../enums/accessPermissionEnum";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import useAuth from "../../../hooks/useAuth";
import { process } from "@progress/kendo-data-query";
import customerDefinedValuesService from "../../../services/customerDefinedValues.service";
import { AxiosError } from "axios";
import scorecardService from "../../../services/scorecard.service";
import { navData } from "../../../data/NavigationData";
import { axiosWithAuth } from "../../../utils/customAxios";
import NotFoundError from "../../error/NotFoundError";
import "./Scorecards.scss";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

const customCellMobile = (props: GridCellProps) => {
  const question = props.dataItem;
  return (
    <td className="listMobile">
      <div className="listRowMobile">
        <div className="tblUsr float-left w-100 p-r-40 p-b-15">
          <div className="topUsrAreaPic">
            <div className="topUsrAreaPic-tx">
              <span className="fs-14 font-weight-bold line-height-2">
                Question
              </span>
              <span className="fs-14 cursor-default text-decoration-none float-left w-100 line-height-2">
                {props.dataItem.questionText}
              </span>
            </div>
          </div>
        </div>
        <div className="iconMobileDetail" style={{ right: "80px" }}>
          <span className="fs-18 text-primary m-r-15" title="Edit">
            <Link to={"#"} className="btn-link text-primary p-0 m-0 fs-18">
              <i className="bi bi-pencil"></i>
            </Link>
          </span>
          <span className="fs-18 text-primary" title="Add">
            <Link to={"#"} className="btn-link text-primary p-0 m-0 fs-18">
              <i className="bi bi-trash3"></i>
            </Link>
          </span>
        </div>
      </div>
    </td>
  );
};
const CustomCellTopicName = (props: any) => {
  return (
    <td className="uCardCol">
      <div className="tblUsr">
        <div className="topUsAreaPic display-flex align-items-center">
          <div className="topUsrAreaPic-tx">
            <span className="font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2">
              {props.dataItem.questionText}
            </span>
          </div>
        </div>
      </div>
    </td>
  );
};
const CelltopicDescription = (props: any) => {
  return (
    <td>
      <div className="line-height-2">
        {Math.round(props.dataItem.weightage * 100) + "%"}
      </div>
    </td>
  );
};

const ScorecardDetail: React.FC = () => {
  const auth = useAuth();
  const localeCtx = useLocale();
  const swal = useSwal();
  const initialDataState = {
    skip: 0,
    take: 10,
  };
  const [page, setPage] = useState(initialDataState);
  const handlePageChange = (event: any) => {
    setPage(event.page);
  };

  const [visible, setVisible] = React.useState<boolean>(false);
  const [editQuestion, setEditQuestion] = useState<
    ScorecardQuestion | undefined
  >(undefined);

  const toggleDialog = (question: ScorecardQuestion | undefined) => {
    setEditQuestion(question);
    setVisible(!visible);
  };

  const [visibleQuestionGroupDialog, setVisibleQuestionGroupDialog] =
    useState<boolean>(false);
  const [editQuestionGroup, setEditQuestionGroup] =
    useState<ScorecardQuestionGroup>();

  const toggleDialogQuestionGroup = (
    questionGroup: ScorecardQuestionGroup | undefined
  ) => {
    setEditQuestionGroup(questionGroup);
    setVisibleQuestionGroupDialog(!visibleQuestionGroupDialog);
  };

  // tabs
  const [selected, setSelected] = React.useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const hasACMangPermission = auth?.checkUserAccess(
    AccessPermissionEnum.ManageScorecards
  );
  const initialSC: Scorecard = {
    id: 0,
    scorecardName: "",
  };
  const { scorecardId } = useParams();
  const [scorecard, setScorecard] = useState<Scorecard>(initialSC);

  const getScorecardById = async () => {
    const response = await scorecardService.fetchScorecardById(
      Number(scorecardId)
    );
    setScorecard(response);
  };

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("ScorecardDetail");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "ScorecardDetail"
        ]
      );
      localeCtx?.setPreviousAppLocale("ScorecardDetail");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "ScorecardDetail"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getScorecardById();
  }, []);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["Scorecard"]);

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const archiveSweetAlert = async() => {
    const swalOptions: SweetAlertOptions<any, any> = {
      icon: "info",
      title: `${
        translationsLoading
          ? "Scorecard will be archived"
          : fetchLabelKeyTranslation(
              "SwtAltArchiveText",
              "Scorecard will be archived" 
            )
      }`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${
        translationsLoading
          ? "Yes, Archive it!"
          : fetchLabelKeyTranslation(
              "SwtAltArchiveBtnText",
              "Yes, Archive it!" 
            )
      }`,
      cancelButtonText: `${
        translationsLoading
          ? "Cancel" 
          : fetchLabelKeyTranslation(
              "SwtAltCancelTitle", 
              "Cancel"
            )
      }`
    };
    const resp = await swal.fire(swalOptions);
    if (resp.isConfirmed) {
      try{
        const updatedScorecard: Scorecard = scorecard;
        updatedScorecard.isArchived = true;
        const updatedScorecardRes =
          await scorecardService.updateScorecardById(
            scorecard.id,
            updatedScorecard
          );
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard Archived"
              : fetchLabelKeyTranslation(
                  "SwtAltArchiveSuccessTitle",
                  "Scorecard Archived" 
                )
          }`,
          text: `${
            translationsLoading
              ? "Scorecard has been archived Sucessfully"
              : fetchLabelKeyTranslation(
                  "SwtAltArchiveSuccessText", 
                  "Scorecard has been archived Sucessfully"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK") 
          }`,
        };
        swal.fire(swalOptions);
        setScorecard(updatedScorecardRes);
      }catch(err){
        // Archiving Failed
        if (err instanceof AxiosError) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation(
                    "SwtAltErrorTitle",
                    "Error"
                  )
            }`
            ,
            text:  `${
              translationsLoading
                ? "Could not archive the Scorecard"
                : fetchLabelKeyTranslation(
                    "SwtAltArchiveErrorText",
                    "Could not archive the Scorecard"
                  )
            }`,
          };
          swal.fire(swalOptions);
        }            
      }
    }   
  }

  const deleteQuestionGroup = async () => {
    const isUsed: boolean = await scorecardService.fetchScorecardUsage(Number(scorecardId));
    if (isUsed) {
      // If user wants to archive
      await archiveSweetAlert();              
    }else{
      // If user wants to delete
      const swalOptions: SweetAlertOptions<any, any> = {
        icon: "warning",
        title: `${
          translationsLoading
            ? "Scorecard Question Group will be deleted"
            : fetchLabelKeyTranslation(
                "SwtAltDeleteWarningTitle",
                "Scorecard Question Group will be deleted" 
              )
        }`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Yes, delete it!"
            : fetchLabelKeyTranslation(
                "SwtAltDeleteConfirmText",
                "Yes, delete it!"
              )
        }`
        ,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation(
                "SwtAltCancelTitle",
                "Cancel" 
              )
        }`,
      };     
      const res = await swal.fire(swalOptions);
      if (res.isConfirmed) {
        try{
          // Delete
          const groupId = scorecard.questionGroups?.at(selected)?.id;
          axiosWithAuth
            .delete(`scorecard/${scorecardId}/scorecardquestiongroup/${groupId}`)
            .then((response) => {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "success",
                title: `${
                  translationsLoading
                    ? "Success"
                    : fetchLabelKeyTranslation(
                        "SwtAltSuccessMessageTitle",
                        "Success" 
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Scorecard Question Group has been deleted"
                    : fetchLabelKeyTranslation(
                        "SwtAltDeleteSuccessText", 
                        "Scorecard Question Group has been deleted"
                      )
                }`,
              };
              swal.fire(swalOptions);
              const updatedGroups: ScorecardQuestionGroup[] =
                scorecard.questionGroups
                  ? scorecard.questionGroups?.filter((item) => {
                      return item.id !== groupId;
                    })
                  : [];
              setScorecard({ ...scorecard, questionGroups: updatedGroups });
              setSelected(0);
            })            
        }catch(err){
          // Deleting failed
          if (err instanceof AxiosError) {
            const swalOptions: SweetAlertOptions<any, any> = {
              icon: "error",
              title: `${
                translationsLoading
                  ? "Error"
                  : fetchLabelKeyTranslation(
                      "SwtAltErrorTitle", 
                      "Error"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "Could not delete the Scorecard Question Group"
                  : fetchLabelKeyTranslation(
                      "SwtAltDeleteErrorText",
                      "Could not delete the Scorecard Question Group"
                    )
              }`,
            };
            swal.fire(swalOptions);
          }            
        }
      }           
    }
  }

  const CustomCellWithActions = (props: GridCellProps) => {
    const handleDeleteQuestion = async () => {
      const isUsed: boolean = await scorecardService.fetchScorecardUsage(Number(scorecardId));
      if (isUsed) {
        // If user wants to archive
        await archiveSweetAlert();
      }
      else{
        // If user wants to delete
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "warning",
          title: `${
            translationsLoading
              ? "Scorecard Question will be deleted"
              : fetchLabelKeyTranslation(
                  "SwtAltDeleteQstnWarningTitle",
                  "Scorecard Question will be deleted"
                )
          }`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `${
            translationsLoading
              ? "Yes, delete it!"
              : fetchLabelKeyTranslation(
                  "SwtAltDeleteConfirmText",
                  "Yes, delete it!"
                )
          }`,
          cancelButtonText: `${
            translationsLoading
              ? "Cancel"
              : fetchLabelKeyTranslation(
                  "SwtAltCancelTitle", 
                  "Cancel"
                )
          }`,
        };   
        const res = await swal.fire(swalOptions);
        if (res.isConfirmed) {
          try{
          // Delete           
          const selectedGroup: ScorecardQuestionGroup | undefined =
            scorecard?.questionGroups?.at(selected);
          const groupId = selectedGroup ? selectedGroup.id : 0;
          var response = await scorecardService.deleteSCQuestion(
            Number(scorecardId),
            groupId,
            props.dataItem.id
          );
          const swalOptions: SweetAlertOptions = {
            icon: "success",
            title: `${
              translationsLoading
                ? "Success"
                : fetchLabelKeyTranslation(
                    "SwtAltSuccessMessageTitle",
                    "Success"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard Question is successfully deleted"
                : fetchLabelKeyTranslation(
                    "SwtAltDeleteQuestionText", 
                    "Scorecard Question is successfully deleted"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK") 
            }`,
          };
          swal.fire(swalOptions);
          const updatedQuestions = selectedGroup?.questions.filter(
            (question: ScorecardQuestion) => question.id !== props.dataItem.id
          );
          if (updatedQuestions?.length) {
            const qs = updatedQuestions;
            const gridDataResult = process(qs, page);
            if (gridDataResult.data.length === 0 && gridDataResult.total > 0) {
              const currentPageNumber = Math.floor(
                gridDataResult.total / page.take
              );
              setPage({
                skip:
                  gridDataResult.total % page.take === 0
                    ? (currentPageNumber - 1) * page.take
                    : currentPageNumber * page.take,
                take: page.take,
              });
            }
          }
          await getScorecardById();           
          }catch(err){
            // Deleting failed
            if (err instanceof AxiosError) {
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation("SwtAltErrorTitle","Error") 
                }`,
                text: `${
                  translationsLoading
                    ? "Could not delete the Scorecard Question"
                    : fetchLabelKeyTranslation("SwtAltDeleteQstnErrorTitle","Could not delete the Scorecard Question")
                }`,
              };
              swal.fire(swalOptions);
            }  
          }
        }        

      }
    }

    return (
      <td>
        <Tooltip anchorElement={"target"} parentTitle={true} position={"top"}>
          <span
            className="fs-18 text-primary m-r-15"
            title={`${
              translationsLoading
                ? "Edit"
                : fetchLabelKeyTranslation(
                    "SCDetailActionsColEditIconTitle", 
                    "Edit"
                  )
            }`}
          >
            <Button
              className={`btn btn-link p-0 m-0 fs-18 ${
                scorecard.isArchived ||
                scorecard.isSystemDefined ||
                !hasACMangPermission
                  ? "disabledIconBtn"
                  : "iconBtn"
              }`}
              onClick={() => toggleDialog(props.dataItem)}
              disabled={
                scorecard.isArchived ||
                scorecard.isSystemDefined ||
                !hasACMangPermission
              }
            >
              <i className="bi bi-pencil"></i>
            </Button>
          </span>
          <span
            className="fs-18 text-primary"
            title={`${
              translationsLoading
                ? "Delete"
                : fetchLabelKeyTranslation(
                    "SCDetailActionsColDeleteIconTitle", 
                    "Delete"
                  )
            }`}
          >
            <Button
              className={`btn btn-link p-0 m-0 fs-18 ${
                scorecard.isArchived ||
                scorecard.isSystemDefined ||
                !hasACMangPermission
                  ? "disabledIconBtn"
                  : "iconBtn"
              }`}
              onClick={handleDeleteQuestion}
              disabled={
                scorecard.isArchived ||
                scorecard.isSystemDefined ||
                !hasACMangPermission
              }
            >
              <i className="bi bi-trash3"></i>
            </Button>
          </span>
        </Tooltip>
      </td>
    );
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageScorecards) ? (
        <UnauthorizedAccess />
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 p-b-30">
                <div className="card cardEffect cardMobile">
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="font-weight-semi d-flex align-items-center">
                      {scorecard?.scorecardName}
                    </span>
                    {auth?.checkUserAccess(
                      AccessPermissionEnum.ManageScorecards
                    ) && (
                      <div className="buttons-container hov-transparent d-flex align-itmes-center">
                        <Link
                          to={"/settings/scorecards"}
                          className="btn-link text-primary p-0 m-0 d-flex align-items-center"
                          state={{ ...navData, backActive: true }}
                        >
                          <i className="bi bi-arrow-left fs-18 p-r-5 line-height-1"></i>
                          <span className="fs-13 font-weight-semi">
                            {`${
                              translationsLoading
                                ? "Back to Scorecards"
                                : fetchLabelKeyTranslation(
                                    "SCDetailBackButton", 
                                    "Back to Scorecards"
                                  )
                            }`}
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="card-body p-0">
                    <div className="tabIner">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="detailSection p-t-16 p-r-10 p-l-20">
                            <div className="settingData k-custom-tab maxTabScroll minHeightTab35 p-t-5 p-l-2 p-b-10">
                              {scorecard.questionGroups?.length === 0 ? (
                                <div
                                  style={{
                                    textAlign: "center",
                                    minHeight: "100px",
                                    float: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    border: "2px dashed #ddd",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <span>
                                    {`${
                                      translationsLoading
                                        ? "No Question Group Found. Click"
                                        : fetchLabelKeyTranslation(
                                            "SCDetailEmptyQuestionGroupListMsg1", 
                                            "No Question Group Found. Click"
                                          )
                                    }`}
                                  </span>
                                  <Button
                                    title={`${
                                      translationsLoading
                                        ? "Create New Question Group"
                                        : fetchLabelKeyTranslation(
                                            "CreateNewQuestionBtntitle",
                                            "Create New Question Group"
                                          )
                                    }`}
                                    className="cardEffect bg-black-2 margin-3 text-black-12 padding-3 p-l-8 p-r-8"
                                    onClick={() =>
                                      toggleDialogQuestionGroup(undefined)
                                    }
                                    disabled={
                                      scorecard.isArchived ||
                                      scorecard.isSystemDefined ||
                                      !hasACMangPermission
                                    }
                                  >
                                    <i className="bi bi-plus-lg fs-20"></i>
                                  </Button>
                                  <span>
                                    {`${
                                      translationsLoading
                                        ? "to add new question group"
                                        : fetchLabelKeyTranslation(
                                            "SCDetailEmptyQuestionGroupListMsg2", 
                                            "to add new question group"
                                          )
                                    }`}
                                  </span>
                                </div>
                              ) : (
                                <TabStrip
                                  tabPosition={"left"}
                                  selected={selected}
                                  onSelect={handleSelect}
                                >
                                  {scorecard.questionGroups?.map(
                                    (group: ScorecardQuestionGroup) => {
                                      return (
                                        <TabStripTab
                                          title={
                                            group.scorecardQuestionGroupName
                                              .length < 25
                                              ? group.scorecardQuestionGroupName
                                              : `${group.scorecardQuestionGroupName
                                                  .slice(0, 25)
                                                  .trimEnd()}...`
                                          }
                                        >
                                          {visible && (
                                            <UpsertQuestionDialogBox
                                              toggleDialog={toggleDialog}
                                              scorecardGroupId={group.id}
                                              scorecardId={Number(scorecardId)}
                                              question={editQuestion}
                                              upsertSC={getScorecardById}
                                              page={page}
                                              setPage={setPage}
                                              scorecard={scorecard}
                                            />
                                          )}
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="tabInrHead d-flex align-items-center justify-content-between p-b-10">
                                                <div
                                                  className="tabInrHeading fs-16 font-weight-semi d-flex align-items-center"
                                                  style={{ flexWrap: "wrap" }}
                                                >
                                                  <span className="float-left text-black-8">
                                                    {`${
                                                      translationsLoading
                                                        ? "Question Group:"
                                                        : fetchLabelKeyTranslation(
                                                            "SCDetailQuestionGroup",
                                                            "Question Group:"
                                                          )
                                                    }`}
                                                  </span>{" "}
                                                  <span className="float-left d-flex align-items-center line-height-2 p-l-1 p-r-3">
                                                    <Tooltip
                                                      anchorElement={"target"}
                                                      parentTitle={true}
                                                      position={"top"}
                                                    >
                                                      <span
                                                        title={`${
                                                          translationsLoading
                                                            ? "Edit"
                                                            : fetchLabelKeyTranslation(
                                                                "SCDetailActionsColEditIconTitle", 
                                                                "Edit"
                                                              )
                                                        }`}
                                                      >
                                                        <Button
                                                          className={`btn btn-link line-height-1 p-r-4 p-l-5 m-l-3 border-0 ${
                                                            scorecard.isArchived ||
                                                            scorecard.isSystemDefined ||
                                                            !hasACMangPermission
                                                              ? "disabledBtn"
                                                              : ""
                                                          }`}
                                                          onClick={() =>
                                                            toggleDialogQuestionGroup(
                                                              group
                                                            )
                                                          }
                                                          disabled={
                                                            scorecard.isArchived ||
                                                            scorecard.isSystemDefined ||
                                                            !hasACMangPermission
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              wordBreak:
                                                                "break-all",
                                                              whiteSpace:
                                                                "pre-wrap",
                                                              maxWidth: "60%",
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            {
                                                              group.scorecardQuestionGroupName
                                                            }
                                                          </span>
                                                          <i className="bi bi-pencil fs-18 m-l-1"></i>
                                                        </Button>
                                                      </span>
                                                    </Tooltip>
                                                  </span>
                                                </div>
                                                <div className="tabInrHeadVBtn">
                                                  <div className="buttons-container hov-transparent d-flex align-itmes-center">
                                                    <Button
                                                      onClick={() =>
                                                        toggleDialog(undefined)
                                                      }
                                                      className={`btn bg-primary text-white fs-13 fw-normal ${
                                                        scorecard.isArchived ||
                                                        scorecard.isSystemDefined ||
                                                        !hasACMangPermission
                                                          ? "disabledBtn"
                                                          : ""
                                                      }`}
                                                      style={{
                                                        height: "29px",
                                                        margin: "-1px 0 0 0",
                                                      }}
                                                      disabled={
                                                        scorecard.isArchived ||
                                                        scorecard.isSystemDefined ||
                                                        !hasACMangPermission
                                                      }
                                                      title={`${
                                                        translationsLoading
                                                          ? "Add"
                                                          : fetchLabelKeyTranslation(
                                                              "SCDetailAddButton", 
                                                              "Add"
                                                            )
                                                      }`}
                                                      //  themeColor={"primary"}
                                                    >
                                                      {translationsLoading
                                                        ? "Add"
                                                        : fetchLabelKeyTranslation(
                                                            "SCDetailAddButton", 
                                                            "Add"
                                                          )}
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {group === null ? (
                                            <div className="deviceContent p-l-1">
                                              <div
                                                className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 text-muted text-center padding-6 d-flex align-items-center justify-content-center"
                                                style={{ height: "238px" }}
                                              >
                                                <div className="tabInrHeadVBtn">
                                                  <div className="buttons-container hov-transparent d-flex align-itmes-center">
                                                    {`${
                                                      translationsLoading
                                                        ? "No Question has been added to this Group"
                                                        : fetchLabelKeyTranslation(
                                                            "SCNoQuestionFoundMsg", 
                                                            "No Question has been added to this Group"
                                                          )
                                                    }`}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="deviceContent p-l-1">
                                              <div className="table-container table-responsive table-overflow-hedden highlightRowPlayer borderLeftSecondChild table-mobile">
                                                <Grid
                                                  data={process(
                                                    group.questions,
                                                    page
                                                  )}
                                                  skip={page.skip}
                                                  take={page.take}
                                                  total={group.questions.length}
                                                  pageable={{
                                                    buttonCount: 4,
                                                    pageSizes: true,
                                                  }}
                                                  onPageChange={
                                                    handlePageChange
                                                  }
                                                >
                                                  <GridColumn
                                                    width={0}
                                                    field="cellMobile"
                                                    cell={customCellMobile}
                                                  />
                                                  <GridColumn
                                                    field="topicName"
                                                    title={`${
                                                      translationsLoading
                                                        ? "Question"
                                                        : fetchLabelKeyTranslation(
                                                            "TextQuestion", 
                                                            "Question"
                                                          )
                                                    }`}
                                                    cell={CustomCellTopicName}
                                                  />
                                                  <GridColumn
                                                    width={150}
                                                    field="topicActions"
                                                    title={`${
                                                      translationsLoading
                                                        ? "Actions"
                                                        : fetchLabelKeyTranslation(
                                                            "SCDetailActionsColHeader", 
                                                            "Actions"
                                                          )
                                                    }`}
                                                    cell={CustomCellWithActions}
                                                  />
                                                </Grid>
                                              </div>
                                            </div>
                                          )}
                                        </TabStripTab>
                                      );
                                    }
                                  )}
                                </TabStrip>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="float-left w-100 d-flex align-items-center justify-content-between p-t-12 p-b-6 p-l-15 p-r-15 m-t-10 border-top-solid border-w-1 border-black-1">
                            <div className="tabAction float-left d-flex align-items-center justify-content-center">
                              {scorecard.questionGroups &&
                                scorecard.questionGroups?.length > 0 && (
                                  <Button
                                    title={`${
                                      translationsLoading
                                        ? "Create New Question Group"
                                        : fetchLabelKeyTranslation(
                                            "CreateNewQuestionBtntitle", 
                                            "Create New Question Group"
                                          )
                                    }`}
                                    className={`cardEffect bg-black-2 margin-3 text-black-12 padding-3 p-l-8 p-r-8 ${
                                      scorecard.isArchived ||
                                      scorecard.isSystemDefined ||
                                      !hasACMangPermission
                                        ? "disabledBtn"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      toggleDialogQuestionGroup(undefined)
                                    }
                                    disabled={
                                      scorecard.isArchived ||
                                      scorecard.isSystemDefined ||
                                      !hasACMangPermission
                                    }
                                  >
                                    <i className="bi bi-plus-lg fs-20"></i>
                                  </Button>
                                )}

                              {scorecard.questionGroups?.length ? (
                                <Button
                                  title={`${
                                    translationsLoading
                                      ? "Delete Current Question Group"
                                      : fetchLabelKeyTranslation(
                                          "DeleteNewQuestionBtntitle", 
                                          "Delete Current Question Group"
                                        )
                                  }`}
                                  className={`cardEffect bg-black-2 margin-3 text-black-12 padding-3 p-l-8 p-r-8 ${
                                    scorecard.isArchived ||
                                    scorecard.isSystemDefined ||
                                    !hasACMangPermission
                                      ? "disabledBtn"
                                      : ""
                                  }`}
                                  onClick={deleteQuestionGroup}
                                  disabled={
                                    scorecard.isArchived ||
                                    scorecard.isSystemDefined ||
                                    !hasACMangPermission
                                  }
                                >
                                  <i className="bi bi-trash fs-20"></i>
                                </Button>
                              ) : (
                                <div></div>
                              )}
                              {visibleQuestionGroupDialog && (
                                <UpsertQuestionGroupDialog
                                  toggleDialog={toggleDialogQuestionGroup}
                                  questionGroup={editQuestionGroup}
                                  scorecardId={scorecardId}
                                  upsertSC={getScorecardById}
                                  setSelected={setSelected}
                                  totalQuestionGroups={
                                    scorecard.questionGroups?.length ?? 0
                                  }
                                  scorecard={scorecard}
                                ></UpsertQuestionGroupDialog>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScorecardDetail;
