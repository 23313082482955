// This screen is created to deal with the issue in VA-1449
import "./error.scss";
import { Link } from "react-router-dom";

const LoginIncompleteError = () => {
  return (
    <div className="errorContainer float-left w-100 text-center">
      <div style={{ height: "120px", width: "auto" }}></div>
      <div className="errorTxt">
        <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
          Something happened during Login
        </div>
        <div className="errorTxt-p fs-16 p-b-20">
          Please try again later or feel free to contact us if the problem
          persists.
        </div>
        <div className="errorBtn">
          <Link
            className="fs-15 bg-primary text-white padding-6 p-l-15 p-r-15"
            replace
            to="/login"
          >
            Back to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginIncompleteError;
